import React, { Component } from 'react';
import HeaderComponent from './headerComponent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as projectActions from './headerActions'
import * as notebookActions from '../actions/notebook'

class Header extends Component {

  constructor(props) {
    super(props)

    this.onProjectChange = this.onProjectChange.bind(this)
  }

  componentDidMount() {
    this.props.actions.fetchProjects()
  }

  onProjectChange(e) {
    this.props.actions.setProjectId(e.target.value)
  }

  render() {
    return (
      <HeaderComponent 
        disableNotebookButton={this.props.disableNotebookButton}
        paragraphTitle={this.props.paragraphTitle}
        notebook={this.props.notebook}
        paragraph={this.props.paragraph}
        projects={this.props.projects}
        currentProjectId={this.props.currentProjectId}
        onProjectChange={this.onProjectChange}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    paragraphTitle: state.paragraphTitle, 
    notebook: state.notebookSingle,
    paragraph: state.paragraph, 
    projects: state.projects, 
    currentProjectId: state.currentProjectId
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, projectActions, notebookActions), dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)