import _ from 'lodash';

export const lineReviewImage = (state = {}, action) => {
  switch(action.type) {
    case 'SET_LINE_REVIEW_IMAGE':
      return action.lineImage
    default:
      return state
  }
}

export const nextLineReviewImage = (state = {}, action) => {
  switch(action.type) {
    case 'SET_NEXT_LINE_REVIEW_IMAGE':
      return action.lineImage
    default:
      return state
  }
}

export const prevLineReviewImage = (state = {}, action) => {
  switch(action.type) {
    case 'SET_PREV_LINE_REVIEW_IMAGE':
      return action.lineImage
    default:
      return state
  }
}

// export const lineSaveSuccess = (state = false, action) => {
//   switch(action.type) {
//     case 'SAVE_LINES_SUCCESS':
//       return action.value
//     case 'SAVE_LINES_RESET':
//       return action.value
//     default:
//       return state
//   }
// }

// export const lineSaveError = (state = {}, action) => {
//   switch(action.type) {
//     case
//     default:
//       return state
//   }
// }

export const lineImageLines = (state = [], action) => {
  switch(action.type) {
    case 'SET_LINE_ANNOTATIONS':
      return action.annotations
    default:
      return state
  }
}

export const lineImageReviewStatus = (state = {}, action) => {
  switch(action.type) {
    case 'SET_PARAGRAPH_INHERITANCE_PROGRESS_STATUS':
      return action.status
    default:
      return state
  }
}

export const lineImageOutline = (state = { outline: [], hasPrevPage: false, hasNextPage: false, totalLineImageAmount: 0 }, action) => {
  switch(action.type) {
    case 'SET_LINE_IMAGE_OUTLINE':
      return action
    default:
      return state
  }
}

export const lineAnnotations = (state = [], action) => {
  switch (action.type) {
    case 'SET_LINE_ANNOTATIONS':
      return action.annotations
    case 'DELETE_LINE_ANNOTATION':
      const newAnnotations =  _.filter(action.annotations, function(o) { 
        return o.identifier !== action.identifier; 
     });

     return newAnnotations
    case 'DELETE_ALL_LINE_ANNOTATIONS':
      return []
    case 'SET_LINE_ANNOTATION_MOUSE_OVER':
      const annotationsMouseOver = _.map(action.annotations, function(o) {
        if(o.identifier === action.identifier) {
          o.mouseover = true;
        }

        return o;
      })

      return annotationsMouseOver
    case 'SET_LINE_ANNOTATION_MOUSE_OUT': 
      const annotationsMouseOut = _.map(action.annotations, function(o) {
        if(o.identifier === action.identifier) {
          o.mouseover = false;
        }

        return o;
      })

      return annotationsMouseOut
    default:
      return state
  }
}