import * as types from '../actions/types'
import axios from 'axios'
import _ from 'lodash'

export const dataExport = ({ projectId, status, notebookId }) => {
  return (dispatch, getState) => {
    axios({
      method: 'GET', 
      url: `/api/project/export/${projectId}/${status}/${notebookId}`, 
    }).then((resp) => {
      console.log({ resp: resp.data })
    })
  }
}

