import React, { Component } from 'react'
import * as uploadActions from '../actions/upload'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dropzone from 'react-dropzone';

class CustomDropzone extends Component {
  
  constructor() {
    super();

    this.onDrop = this.onDrop.bind(this)
    
    this.state = {
      files: []
    }
  }

  onDrop(files) {
    this.props.actions.setAcceptedFiles(files)

    this.setState({files})
  }

  render() {
    const files = this.state.files.map(file => (
      <li key={file.name} className="relative">
        <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
          <img src={URL.createObjectURL(file)} alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
          <button type="button" className="absolute inset-0 focus:outline-none">
            <span className="sr-only">View details for {file.name}</span>
          </button>
        </div>
        <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">{file.name}</p>
      </li>
    ));

    return (
      <Dropzone onDrop={this.onDrop}>
        {({getRootProps, getInputProps}) => (
          <section className="container" style={{ cursor: 'pointer'}}>
            {this.state.files.length === 0 &&
              <span>
                <div {...getRootProps({className: 'dropzone'})}>
                  <aside>
                    <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                      <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </aside>
                  <input {...getInputProps()} directory="" webkitdirectory="" type="file"/>
                  <div className="text-center">
                    <p>Drag and drop a folder, or click to select a folder</p>
                    <p>Only .jpg and .png images less than 10mb inside a folder are uploaded.</p>
                  </div>
                </div>
              </span>
            }
            
            {this.state.files.length > 0 &&
              <aside>
                <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">{files}</ul>
              </aside>
            }
            
          </section>
        )}
      </Dropzone>
    );
  }

}

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, uploadActions), dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomDropzone)