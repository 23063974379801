import React from 'react'
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import { connect } from 'react-redux'
// import { ArrowUpCircleIcon } from '@heroicons/react/solid'
import { ChevronUpIcon, ArrowUpCircleIcon, CheckIcon } from '@heroicons/react/solid'

let UndoRedo = ({ canUndo, canRedo, onUndo, onRedo }) => (
  <p>
    <button 
      className="inline-flex items-center rounded-md border border-transparent bg-white px-2 py-1 text-sm font-small leading-4 text-gray-600 shadow-sm hover:bg-white-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      onClick={onUndo} 
      disabled={!canUndo}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
        <path fillRule="evenodd" d="M9.53 2.47a.75.75 0 010 1.06L4.81 8.25H15a6.75 6.75 0 010 13.5h-3a.75.75 0 010-1.5h3a5.25 5.25 0 100-10.5H4.81l4.72 4.72a.75.75 0 11-1.06 1.06l-6-6a.75.75 0 010-1.06l6-6a.75.75 0 011.06 0z" clipRule="evenodd" />
      </svg>
      Undo
    </button>
    <button 
      className="float-right inline-flex items-center rounded-md border border-transparent bg-white px-2 py-1 text-sm font-small leading-4 text-gray-600 shadow-sm hover:bg-white-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      onClick={onRedo} 
      disabled={!canRedo}
    >
      Redo
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
        <path fillRule="evenodd" d="M14.47 2.47a.75.75 0 011.06 0l6 6a.75.75 0 010 1.06l-6 6a.75.75 0 11-1.06-1.06l4.72-4.72H9a5.25 5.25 0 100 10.5h3a.75.75 0 010 1.5H9a6.75 6.75 0 010-13.5h10.19l-4.72-4.72a.75.75 0 010-1.06z" clipRule="evenodd" />
      </svg>
    </button>
  </p>
)

const mapStateToProps = state => {
  return {
    canUndo: state.textLineAnnotations.past.length > 0,
    canRedo: state.textLineAnnotations.future.length > 0
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onUndo: () => dispatch(UndoActionCreators.undo()),
    onRedo: () => dispatch(UndoActionCreators.redo())
  }
}

UndoRedo = connect(mapStateToProps, mapDispatchToProps)(UndoRedo)

export default UndoRedo