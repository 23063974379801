import { Fragment, useState } from "react";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { XIcon } from "@heroicons/react/outline";
import {
  LinkIcon,
  PlusSmIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function RuleManagerComponent({ 
  setOpen, 
  open, 
  selectedTrigger, 
  setTriggerSelected, 
  triggers, 
  operators, 
  selectedOperator, 
  setOperatorSelected, 
  selectedActionType, 
  actionTypes, 
  setActionTypeSelected, 
  triggerValue, 
  onTriggerValueChange, 
  actionValue, 
  onActionValueChange, 
  onRuleCreateClick, 
  rules
}) {
// export default function RuleManagerComponent() {
  // const [open, setOpen] = useState(true);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1">
                      {/* Header */}
                      <div className="bg-gray-50 px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-lg font-medium text-gray-900">
                              {" "}
                              Rules{" "}
                            </Dialog.Title>
                            <p className="text-sm text-gray-500">
                              A rule triggers automatically when it meets a given condition.
                            </p>
                          </div>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="text-gray-400 hover:text-gray-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Divider container */}
                      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        {/* Project name */}
                        <div className="space-y-1 px-4 sm:grid sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <span
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2 float-left"
                            >
                              {" "}
                              When a{" "}
                            </span>
                            <div className="float-left ml-4 w-32">
                              <Listbox value={selectedTrigger} onChange={setTriggerSelected}>
                                <div className="relative">
                                  <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                    <span className="block truncate">{selectedTrigger.name}</span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                      <SelectorIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Listbox.Button>
                                  <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                      {triggers.map((trigger, triggerIdx) => (
                                        <Listbox.Option
                                          key={triggerIdx}
                                          className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                              active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-900'
                                            }`
                                          }
                                          value={trigger}
                                        >
                                          {({ selectedTrigger }) => (
                                            <>
                                              <span
                                                className={`block truncate ${
                                                  selectedTrigger ? 'font-medium' : 'font-normal'
                                                }`}
                                              >
                                                {trigger.name}
                                              </span>
                                              {selectedTrigger ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </Listbox>
                            </div>
                            <div className="float-left ml-4 w-32">
                              <Listbox value={selectedOperator} onChange={setOperatorSelected}>
                                <div className="relative">
                                  <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                    <span className="block truncate">{selectedOperator.name}</span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                      <SelectorIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Listbox.Button>
                                  <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                      {operators.map((operator, operatorIdx) => (
                                        <Listbox.Option
                                          key={operatorIdx}
                                          className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                              active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-900'
                                            }`
                                          }
                                          value={operator}
                                        >
                                          {({ selectedOperator }) => (
                                            <>
                                              <span
                                                className={`block truncate ${
                                                  selectedOperator ? 'font-medium' : 'font-normal'
                                                }`}
                                              >
                                                {operator.name}
                                              </span>
                                              {selectedOperator ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </Listbox>
                            </div>
                            <div className="float-left ml-4">
                              <input
                                type="text"
                                name="trigger-value"
                                id="trigger-value"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={triggerValue}
                                onChange={onTriggerValueChange}
                              />
                            </div>
                          </div>
                          <div>
                            <span 
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2 float-left"
                            >
                              {" "}
                              Then{" "}
                            </span>
                            <div className="float-left ml-4 w-40">
                              <Listbox value={selectedActionType} onChange={setActionTypeSelected}>
                                <div className="relative">
                                  <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                    <span className="block truncate">{selectedActionType.name}</span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                      <SelectorIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Listbox.Button>
                                  <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                      {actionTypes.map((actionType, actionTypeIdx) => (
                                        <Listbox.Option
                                          key={actionTypeIdx}
                                          className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                              active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-900'
                                            }`
                                          }
                                          value={actionType}
                                        >
                                          {({ selected }) => (
                                            <>
                                              <span
                                                className={`block truncate ${
                                                  selected ? 'font-medium' : 'font-normal'
                                                }`}
                                              >
                                                {actionType.name}
                                              </span>
                                              {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </Listbox>
                            </div>
                            <div className="float-left ml-4">
                              <input
                                type="text"
                                name="action-value"
                                id="action-value"
                                value={actionValue}
                                onChange={onActionValueChange}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="border-t border-b border-gray-200 px-4 py-5 sm:px-6">
                        <div className="flex justify-end space-x-3">
                          <button
                            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={onRuleCreateClick}
                          >
                            Create and Run
                          </button>
                        </div>
                      </div>
                      <div>
                        <table className="min-w-full">
                          <thead className="bg-white">
                            <tr>
                              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Trigger
                              </th>
                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Operator
                              </th>
                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Trigger Value
                              </th>
                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Then
                              </th>
                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                With Value
                              </th>
                              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                <span className="sr-only">Delete</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white">
                            <tr className="border-t border-gray-200">
                              <th
                                colSpan={5}
                                scope="colgroup"
                                className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                              >
                                Runs on text change
                              </th>
                            </tr>
                            {rules.map((rule, ruleIdx) => (
                              <tr
                                key={rule.identifier}
                                className={classNames(ruleIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                              >
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {rule.trigger_key}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{rule.operator}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{rule.trigger_value}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{rule.action_type}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{rule.action_value}</td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                    Delete<span className="sr-only">, {rule.trigger_key} {rule.trigger_value}</span>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

// export default RuleManagerComponent
