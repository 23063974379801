import * as types from './types'
import axios from 'axios'

export const fetchLineReviewImage = (notebookId, lineImageId, imgPresentationWidth) => {
  return (dispatch, getState) => {
    axios({
      method: 'GET',
      url: `/api/paragraph-review/${notebookId}/${lineImageId}/${imgPresentationWidth}`
    }).then((resp) => {
      const image = resp.data.paragraph
      const annotations = resp.data.annotations
      const nextImage = resp.data.next_paragraph
      const prevImage = resp.data.prev_paragraph
      const inheritanceStatus = resp.data.inheritance_status
      console.log({ nextImage: nextImage })
      dispatch({
        type: types.SET_LINE_REVIEW_IMAGE,
        lineImage: image
      })

      dispatch({
        type: types.SET_NEXT_LINE_REVIEW_IMAGE,
        lineImage: resp.data.next_paragraph
      })

      dispatch({
        type: types.SET_PREV_LINE_REVIEW_IMAGE,
        lineImage: prevImage
      })

      dispatch({
        type: types.SET_LINE_ANNOTATIONS, 
        annotations: annotations.length > 0 ? annotations : []
      })

      dispatch({
        type: types.SET_PARAGRAPH_INHERITANCE_PROGRESS_STATUS, 
        status: inheritanceStatus
      })

    }).catch(function(err) {
      console.log('fetchLineReviewImage error: ', err)
    })
  }
}

export const fetchNextLineReviewImage = (notebookId) => {
  axios({
    method: 'GET',
    url: `/api/paragraph-review/${notebookId}/next`
  }).then((resp) => {
    const paragraphId = resp.data.paragraph.identifier

    window.location.replace(`/line-review/${notebookId}/${paragraphId}`)
  }).catch(function(err) {
    console.log('fetchLineReviewImage error: ', err)
  })
}

export const fetchLineImagestatus = (notebookId) => {
  return (dispatch, getState) => {
  }
}

export const fetchLineImageOutline = (notebookId, offset, count) => {
  return (dispatch, getState) => {
    axios({
      method: 'GET',
      url: `/api/paragraph-review/${notebookId}/image-outline/${offset}/${count}`
    }).then((resp) => {
      const outline = resp.data.outline
      const hasPrevPage = resp.data.has_prev_page
      const hasNextPage = resp.data.has_next_page
      const totalLineImageAmount = resp.data.total_record_amount

      dispatch({
        type: types.SET_LINE_IMAGE_OUTLINE,
        outline: outline, 
        hasPrevPage: hasPrevPage,
        hasNextPage: hasNextPage,
        totalLineImageAmount: totalLineImageAmount
      })
      
    }).catch(function (err) {
      console.log(`fetchLineImageOutline error: ${err}`)
    })
  }
}

export const setLineAnnotations = (annotations) => {
  return (dispatch, getState) => {

    const disabledAnnotations = annotations.map(o => {
      
      // If annotation is drawn from bottom to top, the height is negative.
      // This check makes the height positive so that a line image can be created by:
      // from: y, to: y + height
      if(o.height < 0) {
        o.y = o.y + o.height
        o.height = -o.height
      }

      return ({ ...o, isActive: false })
    })

    dispatch({
      type: types.SET_LINE_ANNOTATIONS,
      annotations: disabledAnnotations
    })
  }
}

export const deleteLineImageAnnotation = (identifier) => {
  return (dispatch, getState) => {
    dispatch({
      type: types.DELETE_LINE_ANNOTATION,
      identifier: identifier,
      annotations: getState().lineAnnotations
    })
  }
}

export const deleteAllLineImageAnnotations = () => {
  return (dispatch, getState) => {
    dispatch({
      type: types.DELETE_ALL_LINE_ANNOTATIONS
    })
  }
}

export const setLineAnnotationMouseOver = (identifier) => {
  return (dispatch, getState) => {
    dispatch({
      type: types.SET_LINE_ANNOTATION_MOUSE_OVER,
      identifier: identifier, 
      annotations: getState().lineAnnotations
    })
  }
}

export const setLineAnnotationMouseOut = (identifier) => {
  return (dispatch, getState) => {
    dispatch({ 
      type: types.SET_LINE_ANNOTATION_MOUSE_OUT,
      identifier: identifier,
      annotations: getState().paragraphAnnotations
    })
  }
}

export const toggleLineAnnotationActivation = (identifier) => {
  return (dispatch, getState) => {
    const annotations = getState().lineAnnotations
    
    const toggledAnnotations = annotations.map(o => 
      o.identifier === identifier ? ({ ...o, isActive: !o.isActive }) : ({ ...o, isActive: false }))
    
    dispatch({
      type: types.SET_LINE_ANNOTATIONS, 
      annotations: toggledAnnotations
    })  
  }
}

export const uploadLineAnnotations = (notebookId, paragraphId, imgRotateAngle) => {
  return (dispatch, getState) => {
    const annotations = getState().lineAnnotations

    const formData = new FormData()
    formData.append('annotations', JSON.stringify(annotations))
    formData.append('paragraphId', paragraphId)
    formData.append('notebookId', notebookId)
    formData.append('imgRotateAngle', imgRotateAngle)

    axios({
      method: 'POST',
      url: `/api/paragraph-line-annotations/${paragraphId}`,
      data: formData
    }).then((resp) => {
      dispatch({
        type: types.SAVE_LINES_SUCCESS, 
        value: true
      })
    }).catch((err) => {
      console.log(err)
      dispatch({
        type: types.SAVE_LINES_ERROR,
        value: 'Something went wrong'
      })
    })
  }
}

export const resetSaveLinesStatus = () => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SAVE_LINES_SUCCESS', 
      value: false
    })
  }
}