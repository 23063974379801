import React, { Component } from 'react';
import TextExportComponent from '../components/textExport';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class TextExport extends Component {

  constructor(props) {
    super(props)

    this.state = {}

    this.onCsvExportClick = this.onCsvExportClick.bind(this)
  }

  onCsvExportClick() {

  }

  render() {
    return (
      <TextExportComponent onCsvExportClick={this.onCsvExportClick}/>
    )
  }

}


function mapStateToProps(state) {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}), dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TextExport)
