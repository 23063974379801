export const textReviewParagraphOutline = (state = { outline: [], hasPrevPage: false, hasNextPage: false, totalParagraphAmount: 0 }, action) => {
  switch(action.type) {
    case 'SET_TEXT_REVIEW_PARAGRAPH_OUTLINE':
      return action
    default:
      return state
  }
}


export const nextTextReviewParagraph = (state = {}, action) => {
  switch(action.type) {
    case 'SET_NEXT_TEXT_REVIEW_PARAGRAPH':
      return action.paragraph
    default:
      return state
  }
}

export const prevTextReviewParagraph = (state = {}, action) => {
  switch(action.type) {
    case 'SET_PREV_TEXT_REVIEW_PARAGRAPH':
      return action.paragraph
    default:
      return state
  }
}

export const textReviewLines = (state = [], action) => {
  switch(action.type) {
    case 'SET_TEXT_REVIEW_LINES':
      let lines = []

      for(let index in action.lines) {
        let line = action.lines[index]
        
        if(line.value === null) {
          line.value = ''
        }

        lines.push(line)
      }
      return lines
    default:
      return state
  }
}

export const textReviewParagraph = (state = {}, action) => {
  switch(action.type) {
    case 'SET_TEXT_REVIEW_PARAGRAPH':
      return action.paragraph
    default:
      return state 
  }
}

// export const textReviewParagraphPosition = (state = 0, action) => {
//   switch(action.type) {
//     case 'SET_TEXT_REVIEW_PARAGRAPH_POSITION':
//       return action.currentParagraphPosition
//     default:
//       return state 
//   }
// }