import React from 'react';
import Header from '../Header/header'

import Notification from '../containers/notification'

import { Link, Redirect } from 'react-router-dom'
import { CheckIcon } from '@heroicons/react/solid'

const steps = [
  { id: '01', name: '1. Upload Images', href: '#', status: 'complete' },
  { id: '02', name: '2. Review Paragraphs', href: '#', status: 'complete' },
  { id: '03', name: '3. Review Text', href: '#', status: 'complete' },
  { id: '04', name: '4. Download Text', href: '#', status: 'current' }
]

const TextExport = ({ 
  onCsvExportClick
}) => (
  <div className="bg-gray-100 min-h-screen relative">
    <div className="fixed left-0 top-0 w-full z-10">
      <Header disableNotebookButton={true}/>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <nav aria-label="Progress">
          <ol role="list" className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0 bg-white mt-8">
            {steps.map((step, stepIdx) => (
              <li key={step.name} className="relative md:flex-1 md:flex">
                {step.status === 'complete' ? (
                  <a className="group flex items-center w-full">
                    <span className="px-6 py-4 flex items-center text-sm font-medium">
                      <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                        <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
                      </span>
                      <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                    </span>
                  </a>
                ) : step.status === 'current' ? (
                  <a className="px-6 py-4 flex items-center text-sm font-medium" aria-current="step">
                    <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full">
                      <span className="text-indigo-600">{step.id}</span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-indigo-600">{step.name}</span>
                  </a>
                ) : (
                  <a className="group flex items-center">
                    <span className="px-6 py-4 flex items-center text-sm font-medium">
                      <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                        <span className="text-gray-500 group-hover:text-gray-900">{step.id}</span>
                      </span>
                      <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</span>
                    </span>
                  </a>
                )}

                {stepIdx !== steps.length - 1 ? (
                  <>
                    {/* Arrow separator for lg screens and up */}
                    <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                      <svg
                        className="h-full w-full text-gray-300"
                        viewBox="0 0 22 80"
                        fill="none"
                        preserveAspectRatio="none"
                      >
                        <path
                          d="M0 -2L20 40L0 82"
                          vectorEffect="non-scaling-stroke"
                          stroke="currentcolor"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </>
                ) : null}
              </li>
            ))}
          </ol>
        </nav>
      </div>
    </div>

    <div>
      <div className="max-w-7xl mx-auto">
        <div className="px-4 flex justify-between sm:px-0">

          <div className="w-5/8 px-4 sm:px-6 lg:px-8">
            <div className="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group block w-full rounded-lg" style={{'marginTop': 200}}>
              <div className="bg-white shadow overflow-hidden sm:rounded-md p-8 ">
                <button
                  type="button"
                  className="inline-flex mr-8 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Download text as CSV
                </button>

                <button
                  type="button"
                  className="inline-flex mr-8 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Download text as SQL
                </button>

                <button
                  type="button"
                  className="inline-flex mr-8 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Download text as Excel
                </button>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </div>
    
    <footer className="bg-white shadow-sm border-t border-gray-200 fixed w-full bottom-0 left-0">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="px-4 flex items-center justify-between sm:px-0">
          <div className="-mt-px w-0 flex-1 flex justify-end pt-4 pb-4 ">
            <Link to={`/notebooks`}>
              <button
                className="mr-4 ml-8 whitespace-nowrap inline-flex items-center justify-center px-10 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700">
                  Go to notebook list
                  <kbd className="ml-2 text-right inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-200">
                  ⌘K
                </kbd>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  </div>
)

export default TextExport