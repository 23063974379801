import React, { Component } from 'react'
import { Stage, Layer, Rect, Image, Transformer } from 'react-konva'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { rainbow, uuidv4 } from '../utils'
import * as textLineActions from '../TextLineReview/textLineActions'
import Konva from "konva";

function hexToRgbA(hex){
  var c;
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      c= hex.substring(1).split('');
      if(c.length === 3){
          c= [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c= '0x'+c.join('');
      return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.6)';
  }
  throw new Error('Bad Hex');
}

class URLImage extends Component {
  state = {
    image: null
  };
  componentDidMount() {
    this.loadImage();
  }
  componentDidUpdate(oldProps) {
    if (oldProps.src !== this.props.src) {
      this.loadImage();

      console.log('img britghtness: ', this.props.imgBrightness)
    }
  }
  componentWillUnmount() {
    this.image.removeEventListener('load', this.handleLoad);
  }
  loadImage() {
    // save to "this" to remove "load" handler on unmount
    this.image = new window.Image();
    this.image.src = this.props.src;
    this.image.setAttribute('style', 'filter:brightness(-0.3)' );
    this.image.addEventListener('load', this.handleLoad);
  }
  handleLoad = () => {
    // after setState react-konva will update canvas and redraw the layer
    // because "image" property is changed
    this.setState({
      image: this.image
    });
    // if you keep same image object during source updates
    // you will have to update layer manually:
    // this.imageNode.getLayer().batchDraw();
  };
  render() {
    const width = this.props.imgPresentationWidth
    const height = parseInt(this.props.height * this.props.imgPresentationWidth / this.props.width)
    console.log('img britghtness 22: ', this.props.imgBrightness)
    return (
      <Image
        x={this.props.x + width / 2}
        y={this.props.y + height / 2}
        image={this.state.image}
        offsetX={this.props.x + width / 2}
        offsetY={this.props.y + height / 2}
        scaleX={this.props.scaleX}
        scaleY={this.props.scaleY}
        
        ref={node => {
          this.imageNode = node;
        }}
        width={width}
        height={height}
        filters={[Konva.Filters.Brighten]}
        brightness={0.1}
      />
    );
  }
}

class Rectangle extends React.Component {
  handleChange = e => {
    const shape = e.target;
    // take a look into width and height properties
    // by default Transformer will change scaleX and scaleY
    // while transforming
    // so we need to adjust that properties to width and height
    this.props.onTransform({
      x: shape.x(),
      y: shape.y(),
      width: shape.width() * shape.scaleX() ,
      height: shape.height() * shape.scaleY(),
      rotation: shape.rotation()
    });
  };
  render() {
    return (
      <Rect
        x={this.props.x}
        y={this.props.y}
        width={this.props.width}
        height={this.props.height}
        // force no scaling
        // otherwise Transformer will change it
        scaleX={1}
        scaleY={1}
        fill="transparent"
        name={this.props.identifier}
        // save state on dragend or transformend
        onDragEnd={this.handleChange}
        onTransformEnd={this.handleChange}
        // stroke="rgba(0, 0, 0, 0.2)"
        stroke={hexToRgbA(this.props.color)}
        strokeWidth={4}
        draggable
        _useStrictMode
      />
    );
  }
}

class TransformerComponent extends Component {
  constructor(props) {
    super(props);

    this.checkNode = this.checkNode.bind(this);
  }

  componentDidMount() {
    this.checkNode();
    this.transformer.find("Rect").fill("white");
    this.transformer.find("Rect").map(elem => elem.width(9));
    this.transformer.find("Rect").map(elem => elem.height(9));
    this.transformer.find("Rect").map(elem => elem.cornerRadius(1));
    this.transformer.find("Shape").map(elem => elem.stroke("#f3f4f4"));
    this.transformer.find("Shape").map(elem => elem.strokeWidth(1));
    this.transformer.find(".back")[0].sceneFunc(ctx => {
      const tr = this.transformer;
      const shape = this.transformer.find(".back")[0];
      const padding = tr.getPadding();
      ctx.beginPath();
      ctx.rect(
        -padding,
        -padding,
        shape.width() + padding * 2,
        shape.height() + padding * 2
      );
      ctx.moveTo(shape.width() / 2, shape.height() + padding);
      if (tr.rotateEnabled()) {
        ctx.lineTo(
          shape.width() / 2,
          shape.height() +
            tr.rotateAnchorOffset() * Konva.Util._sign(shape.height())
        );
      }

      ctx.fillStrokeShape(shape);
    });
    const shape = this.transformer.find(".back")[0];
    this.transformer
      .find(".rotater")[0]
      .y(shape.height() + this.transformer.rotateAnchorOffset());
  }

  componentDidUpdate() {
    this.checkNode();
  }

  checkNode() {
    const stage = this.transformer.getStage();
    const { selectedAnnotationId } = this.props;

    const selectedNode = stage.findOne("." + selectedAnnotationId);
    if (selectedNode === this.transformer.node()) {
      return;
    }

    if (selectedNode) {
      this.transformer.nodes([selectedNode]);
    } else {
      this.transformer.detach();
    }
    this.transformer.getLayer().batchDraw();
  }
  render() {
    return (
      <Transformer
        ref={node => {
          this.transformer = node;
        }}
        keepRatio={false}
        rotateAnchorOffset={25}
        _useStrictMode
      />
    );
  }
}

class LineCanvasDrawer extends Component {

  constructor(props) {
    super(props)

    this.handleMouseDown = this.handleMouseDown.bind(this)
    this.handleMouseUp = this.handleMouseUp.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleRectangleSelect = this.handleRectangleSelect.bind(this)

    this.state = {
      newAnnotation: [],
      selectedAnnotationId: ""
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', e => {
      if (e.key === "Backspace" && !this.props.hasAnnotateBlock) {
        if (this.state.selectedAnnotationId !== "") {
          this.props.actions.deleteTextLineImageAnnotation(this.state.selectedAnnotationId)
        }
      }
    });
  }

  handleMouseDown(event) {
    const identifier = event.target.name()

    if(!event.target.getParent()) {
      return
    }

    const clickedOnTransformer = event.target.getParent().className === "Transformer";

    if(clickedOnTransformer) {
      return;
    }

    if(this.state.newAnnotation.length === 0 && 
      !this.props.annotations.find(a => a.identifier === identifier) &&
      this.state.selectedAnnotationId === ""
    ) {
      const { x, y } = event.target.getStage().getPointerPosition();
      const annotations = this.props.annotations
      const baseColor = rainbow(annotations.length > 1 ? annotations.length : 1, annotations.length + 1)
      // const blendedColor = blendColors()
      this.setState({ 
        newAnnotation: [{ x, y, width: 0, height: 0, key: "0", color: baseColor }]
      })
    } else if(this.props.annotations.find(a => a.identifier === identifier)) {
      this.setState({
        selectedAnnotationId: identifier
      });
    } else if(this.state.newAnnotation.length === 0 && 
      !this.props.annotations.find(a => a.identifier === identifier) &&
        this.state.selectedAnnotationId !== ""
    ) {
      this.setState({
        selectedAnnotationId: ""
      })
    }

  }

  handleMouseUp(event) {
    if(this.state.newAnnotation.length === 1) {
      const sy = this.state.newAnnotation[0].y
      const color = this.state.newAnnotation[0].color
      const { y } = event.target.getStage().getPointerPosition()
      const annotationToAdd = {
        identifier: uuidv4(),
        x: 0,
        y: parseInt(sy), 
        width: this.props.imgPresentationWidth,
        height: parseInt(y - sy),
        key: this.props.annotations.length + 1, 
        color: color, 
        imgPresentationWidth: this.props.imgPresentationWidth, 
        imgOriginalWidth: this.props.width,
        imgOriginalHeight: this.props.height, 
        creator: 'user', 
        conf_score: 1,
      }

      const annotations = [...this.props.annotations, annotationToAdd]

      this.setState({ 
        newAnnotation: [],
        annotations: annotations
      })

      this.props.actions.setTextLineAnnotations(annotations)
    }
  }

  handleMouseMove(event) {
    if(this.state.newAnnotation.length === 1) {
      const sy = this.state.newAnnotation[0].y
      const color = this.state.newAnnotation[0].color
      const { x, y } = event.target.getStage().getPointerPosition()
      this.setState({ 
        newAnnotation: [{ 
          x: 0, 
          y: sy, 
          width: this.props.imgPresentationWidth, 
          height: y - sy,
          key: "0", 
          color: color, 
          imgPresentationWidth: this.props.imgPresentationWidth, 
          imgOriginalWidth: this.props.width,
          imgOriginalHeight: this.props.height
        }]
      })
    }
  }

  handleRectangleSelect(event) {
    const identifier = event.target.attrs.id
  }

  handleRectChange = (index, newProps) => {
    const annotations = this.props.annotations.concat();
    annotations[index] = {
      ...annotations[index],
      ...newProps
    };

    this.props.actions.setTextLineAnnotations(annotations)
  };
  
  render() {
    const width = this.props.imgPresentationWidth
    const height = parseInt(this.props.height * this.props.imgPresentationWidth / this.props.width)
    const annotationsToDraw = [...this.props.annotations, ...this.state.newAnnotation]
    // const scale = this.props.lineCanvasDrawerBoxWidth / this.props.imgPresentationWidth

    return (
      <span>
        {this.props.imageUrl && 
          <Stage
            onMouseDown={this.handleMouseDown}
            onMouseUp={this.handleMouseUp}
            onMouseMove={this.handleMouseMove}
            width={width}
            height={height}
            // scale={{ x: scale, y: scale }}
          >
            <Layer key={1000}>
              <URLImage src={window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/' + this.props.imageUrl} 
                        x={0}
                        y={0} 
                        // width={700}
                        // height={900}
                        imgPresentationWidth={this.props.imgPresentationWidth}
                        width={width}
                        height={height}
                        scaleX={this.props.scaleX}
                        scaleY={this.props.scaleY}
                        imgBrightness={parseFloat(this.props.imgBrightness)}
              />
            </Layer>
            <Layer key='left-vertical'>
              {/* <Line points={[parseInt(width * 0.25), 0, parseInt(width * 0.25), height]} stroke='rgba(79, 70, 229, 0.5)'></Line> */}
              {/* <Line points={[parseInt(width * 0.75), 0, parseInt(width * 0.75), height]} stroke='rgba(79, 70, 229, 0.5)'></Line> */}
              {/* <Line points={[0, parseInt(height * 0.25), width, parseInt(height * 0.25)]} stroke='rgba(79, 70, 229, 0.5)'></Line> */}
              {/* <Line points={[0, parseInt(height * 0.75), width, parseInt(height * 0.75)]} stroke='rgba(79, 70, 229, 0.5)'></Line> */}
            </Layer>
            <Layer key={1001} _useStrictMode>
              {annotationsToDraw.map((value, i) => {
                return (
                  <Rectangle 
                      key={i} 
                      {...value}
                      onTransform={newProps => {
                        this.handleRectChange(i, newProps);
                      }}
                    />                 
                )
              })}
              <TransformerComponent
                  selectedAnnotationId={this.state.selectedAnnotationId}
                />
            </Layer>
          </Stage>
        }
      </span>
    )
  }
}

function mapStateToProps(state) {
  return {
    annotations: state.textLineAnnotations.present,
    hasAnnotateBlock: state.hasAnnotateBlock
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, textLineActions), dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LineCanvasDrawer)