import React, { useState, useRef, useEffect } from 'react';
import { Stage, Layer, Image, Rect, Line } from 'react-konva';
import _ from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux'

const translatePoint = ({ offsetX, offsetY, scale, positionX, positionY }) => {
  const point = {
    x: (offsetX + -positionX) / scale, 
    y: (offsetY + -positionY) / scale
  }
  return point 
}

const DrawingCanvas = ({ backgroundImageUrl }) => {
  const dispatch = useDispatch()

  const isMouseActive = useSelector((state) => state.canvasIsMouseActive)
  const imgStageSize = useSelector((state) => state.imgStageSize)
  const imageRef = useRef(null);
  const stageRef = useRef(null);

  const [randomState, setStateToken] = useState(0)
  
  const position = useSelector((state) => state.canvasPosition)
  const rectangles = useSelector((state) => state.rectangles)
  const scale = useSelector((state) => state.canvasScale)
  const drawingMode = useSelector((state) => state.canvasDrawingMode)
  
  const polygons = useSelector((state) => state.polygons)
  const isMovingPoint = useSelector((state) => state.polygonIsMovingPoint)
  const [image, setImage] = useState(null)

  // Draw background image to the Konva canvas
  useEffect(() => {
    if(!backgroundImageUrl) return;

    const img = new window.Image()
    img.onload = () => {
      setImage(img)
      stageRef.current.batchDraw()
    }
    img.src = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/' + backgroundImageUrl
    
    // Generate random integer to force React state to update
    setStateToken(Math.random())
  }, [backgroundImageUrl])

  const onPointMouseEnter = (e) => {
    if(isMouseActive) return
    
    dispatch({ type: 'CANVAS_POLYGON_IS_MOVING_POINT', isMovingPoint: true })
    e.target.getStage().container().style.cursor = 'grab'
  }

  const onPointMouseLeave = (e) => {
    if(isMouseActive) return
    
    dispatch({ type: 'CANVAS_POLYGON_IS_MOVING_POINT', isMovingPoint: false })
    e.target.getStage().container().style.cursor = 'crosshair'
  }

  const handleZoomIn = () => {
    dispatch({ type: 'CANVAS_SET_SCALE', scale: Math.min(scale + 0.1, 2) })
    // setScale(Math.min(scale + 0.1, 2));
  };

  const handleZoomOut = () => {
    dispatch({ type: 'CANVAS_SET_SCALE', scale: Math.max(scale - 0.1, 0.5) })
    // setScale(Math.max(scale - 0.1, 0.5));
  };

  const setDrawingMode = (drawingMode) => {
    dispatch({ type: 'CANVAS_SET_DRAWING_MODE', drawingMode: drawingMode })
  }

  /*
    Behavior when mouse moves: 
    For rectangles: 
      - Prerequisite: mouse needs to be down
      - Rectangle is updated with diff between start position and current cursor position
    
    For polygons (closed line): 
      - Prerequisite: mouse needs to be down
      - Last line coordinates are continously updated with current cursor position
  */
  const handleMouseMove = (e) => {
    const { offsetX, offsetY } = e.evt;

    if (drawingMode === 'rect' && isMouseActive && !isMovingPoint) {
      const lastIndex = rectangles.length - 1;
      if (lastIndex >= 0) {
        const width = offsetX - rectangles[lastIndex].x;
        const height = offsetY - rectangles[lastIndex].y;

        const updatedRectangles = [...rectangles];
        updatedRectangles[lastIndex] = { ...updatedRectangles[lastIndex], width, height };
        
        dispatch({ type: 'CANVAS_RECTANGLES_SET', rectangles: updatedRectangles })
      }
    }

    if (drawingMode === 'polygon' && isMouseActive && !isMovingPoint) {
      dispatch({ 
        type: 'CANVAS_ADD_POLYGON_POINT', 
        point: translatePoint({ offsetX, offsetY, scale, positionX: position.x, positionY: position.y}) 
      })
    }
  };

  const handleWheel = (e) => {
    e.evt.preventDefault()
    const currentStageRef = stageRef.current;
  
    if (!currentStageRef) return 
    const stage = currentStageRef.getStage();

    if (e.evt.ctrlKey) {
      const oldScale = scale

      const mousePointTo = {
        x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
        y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
      }

      const unboundedNewScale = oldScale - e.evt.deltaY * 0.01
      let newScale = unboundedNewScale
      if (unboundedNewScale < 0.1) {
        newScale = 0.1
      } else if (unboundedNewScale > 10.0) {
        newScale = 10.0
      }

      const newPosition = {
        x:
          -(mousePointTo.x - stage.getPointerPosition().x / newScale) *
          newScale,
        y:
          -(mousePointTo.y - stage.getPointerPosition().y / newScale) *
          newScale
      }

      dispatch({ type: 'CANVAS_SET_SCALE', scale: newScale })
      dispatch({ type: 'CANVAS_SET_POSITION', position: newPosition })
    } else {
      const dragDistanceScale = 0.75;
      const newPosition = {
        x: position.x - dragDistanceScale * e.evt.deltaX,
        y: position.y - dragDistanceScale * e.evt.deltaY
      }

      dispatch({ type: 'CANVAS_SET_POSITION', position: newPosition })
    }
  }

  /*
    Behavior when mouse went down: 
    For rectangles: 
      - setMouseActive: true 
      - Initiate rectangle at given location with size 0 
    
    For polygon: 
      - setMouseActive: true 
      - Initiate polygon at given location with size 0 
  */
  const handleMouseDown = (e) => {
    const { offsetX, offsetY } = e.evt;

    dispatch({ type: 'CANVAS_IS_MOUSE_ACTIVE', canvasIsMouseActive: true })

    if (drawingMode === 'rect' && !isMovingPoint) {
      // Initiate new rectangle 
      dispatch({
        type: 'CANVAS_SET_RECTANGLES', 
        rectangles: [...rectangles, { x: offsetX, y: offsetY, width: 0, height: 0 }]
      })
    } else if (drawingMode === 'polygon' && !isMovingPoint) {
      dispatch({ 
        type: 'CANVAS_ADD_POLYGON', 
        point: translatePoint({ offsetX, offsetY, scale, positionX: position.x, positionY: position.y}) 
      })
    }
  };

  /* 
    Behavior when mouse went up
    For rectangles: 
      - setMouseActive: false 
    
    For polygons: 
        - setMouseActive continues to be true unless: 
        - polygon is closed
        - ESC button was pressed, causing the line to be removed
  */ 
  const handleMouseUp = (e) => {
    dispatch({ type: 'CANVAS_IS_MOUSE_ACTIVE', canvasIsMouseActive: false })
    dispatch({ type: 'CANVAS_DEACTIVATE_POLYGONS' })
    dispatch({ type: 'CANVAS_POLYGON_IS_MOVING_POINT', isMovingPoint: false })
    e.target.getStage().container().style.cursor = 'grab'
  };

  const anchorDragMove = (e, anchorId, polygonId, scale) => {
    const { offsetX, offsetY } = e.evt;
    const anchor = translatePoint({ offsetX, offsetY, scale, positionX: position.x, positionY: position.y})
    const anchorPosition = { x: anchor.x, y: anchor.y } 

    dispatch({ type: 'CANVAS_MOVE_ANCHOR', anchorId: anchorId, position: anchorPosition, polygonId: polygonId })
  }

  return (
    <React.StrictMode>
      <div>
        <div>
          <button
            type="button"
            className={
              (drawingMode === 'rect' ? 'bg-indigo-600 text-white ring-black-300 hover:bg-indigo-700' : 'bg-white text-gray-900 ring-gray-300 hover:bg-gray-50') +
              ` rounded px-2 py-1 text-sm font-semibold shadow-sm ring-1 ring-inset`
            }
            onClick={() => setDrawingMode('rect')}
          >Draw Rectangle</button>
          <button 
            type="button"
            className={
              (drawingMode === 'polygon' ? 'bg-indigo-600 text-white ring-black-300 hover:bg-indigo-700' : 'bg-white text-gray-900 ring-gray-300 hover:bg-gray-50') +
              ` rounded px-2 py-1 text-sm font-semibold shadow-sm ring-1 ring-inset`
            }
            onClick={() => setDrawingMode('polygon')}
          >Draw Polygon</button>
          <button 
            type="button"
            className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={handleZoomIn}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607ZM10.5 7.5v6m3-3h-6" />
            </svg>
          </button>
          <button 
            type="button"
            className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={handleZoomOut}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607ZM13.5 10.5h-6" />
            </svg>
          </button>
        </div>
        {/* {isImageLoaded ? ( */}
          <Stage
            ref={stageRef}
            width={imgStageSize.w}
            height={imgStageSize.h}
            onMouseDown={(e) => handleMouseDown(e)}
            /* Used to draw new objects */
            onMouseMove={(e) => handleMouseMove(e)}
            onMouseUp={(e) => handleMouseUp(e)}
            onMouseEnter={e => {
              const container = e.target.getStage().container();
              container.style.cursor = "crosshair";
            }}
            onMouseLeave={e => {
              const container = e.target.getStage().container();
              container.style.cursor = "default";
            }}
            onWheel={handleWheel}
            scaleX={scale}
            scaleY={scale}
            x={position.x}
            y={position.y}
          >
            <Layer>
              {image && 
                <Image
                  image={image}
                  ref={imageRef}
                  width={imgStageSize.w}
                  height={imgStageSize.h}
                />
              }
              {rectangles.map((rect, index) => (
                <Rect
                  key={index}
                  {...rect}
                  stroke="black"
                  strokeWidth={2}
                  // onClick={() => handleSelectShape(rect)}
                  // draggable
                />
              ))}
              {polygons.map((line, index) => (
                <Line
                  key={index}
                  points={line.points}
                  stroke="#4f46e5"
                  strokeWidth={1}
                  strokeScaleEnabled={false}
                  closed={true}
                  shadowEnabled={true}
                />
              ))}   
              {polygons.map((polygon, Pindex) => (
                polygon.anchors.map((point, index) => (
                  <Rect
                    key={point.id}
                    fill="#4f46e5"
                    width={8 / scale}
                    height={8 / scale}
                    x={point.x - (4 / scale)}
                    y={point.y - (4 / scale)}
                    draggable={true}
                    // onDragMove={(e) => pointDragMove(e, Pindex, index, scale)}
                    onDragMove={(e) => anchorDragMove(e, point.id, polygon.id, scale)}
                    onMouseOver={(e) => onPointMouseEnter(e)}
                    onMouseLeave={(e) => onPointMouseLeave(e)}
                    hitStrokeWidth={15}
                    strokeScaleEnabled={false}
                />
                ))
              ))}
            </Layer>
          </Stage>
        {/* ) : (
          <div>Loading background image...</div>
        )} */}
      </div>
    </React.StrictMode>
  );
};

const mapStateToProps = state => {
  return { lines: state.lines };
}

export default connect(mapStateToProps)(DrawingCanvas);
