import React from 'react';
import { Link } from 'react-router-dom'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

const items = [
  { name: 'New Project', href: '/project/new' }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Header = ({ 
  paragraph, 
  notebook, 
  disableNotebookButton, 
  paragraphTitle, 
  projects, 
  currentProjectId, 
  onProjectChange
}) => (
  <header className="bg-white shadow-sm lg:static lg:overflow-y-visible">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8">
        <div className="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-1">
          <div className="flex-shrink-0 flex items-center">
            <Link to={`/${currentProjectId}`}>
              <img className="block h-8 w-auto" src="/logo.png" alt="Workflow"/>
            </Link>
          </div>
        </div>
        <div className="flex-1 py-2 md:px-2 xl:col-span-2">
          <select
            id="location"
            name="location"
            className="block w-full rounded-md border-gray-300 pl-3 pr-10 pt-1 pb-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-gray-500"
            onChange={onProjectChange}
            value={currentProjectId}
            style={{"fontSize": "0.8rem"}}
          >
            {projects.map((project, index) => 
              <option value={project.identifier}>{project.name}</option>
            )}
          </select>
        </div>
        <div className="flex-1 py-2 md:px-2 xl:col-span-2">
          <select
              id="notebook"
              name="notebook"
              className="block w-full rounded-md border-gray-300 pl-3 pr-10 pt-1 pb-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-gray-500"
              style={{"fontSize": "0.8rem"}}
              value="notebook"
            >
              <option>Notebook</option>
          </select>
        </div>
        {/* <div className="min-w-0 flex-1 md:px-6 lg:px-0 xl:col-span-4">
          <div className="flex items-center px-6 py-2 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
            <div className="w-full">
              <label htmlFor="search" className="sr-only">Search</label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                  <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                  </svg>
                </div>
                <input 
                  id="search"  
                  tabIndex="-1" 
                  name="search" 
                  className="block w-full bg-white border border-gray-300 rounded-md px-2 pl-10 pr-3 text-xs placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" 
                  placeholder="Search" 
                  type="search"
                  style={{"lineHeight": 0.5, "fontSize": "0.8rem"}}/>
              </div>
            </div>
          </div>
        </div> */}
        <div className="flex items-center md:absolute md:right-0 md:inset-y-0 lg:hidden">
          <button type="button" className="-mx-2 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" aria-expanded="false">
            <span className="sr-only">Open menu</span>

            <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">
          {!disableNotebookButton &&
            <div className="inline-flex rounded-md shadow-sm">
              <Link to={`/${currentProjectId}/notebook/new`}>
                <button
                  type="button"
                  className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                >
                  New Notebook
                </button>
              </Link>
              <Menu as="div" className="relative -ml-px block">
                <Menu.Button className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
                  <span className="sr-only">Open options</span>
                  <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 -mr-1 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {items.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <Link
                              to={item.href}
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm'
                              )}
                            >
                              {item.name}
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          }
        </div>
      </div>
    </div>
  </header>
) 

export default Header