import React, { Component } from 'react';
import RuleManagerComponent from './RuleManagerComponent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as RuleManagerActions from './RuleManagerActions'

class RuleManager extends Component {

  constructor(props) {
    super(props)

    this.state = {
      open: false, 
      selectedTrigger: {
        name: 'Paragraph'
      }, 
      triggers: [
        { name: 'Paragraph' }
      ], 
      selectedOperator: {
        name: 'contains'
      }, 
      operators: [
        { name: 'contains' }, 
        { name: '=' }, 
        { name: '>' }, 
        { name: '<' }, 
        { name: '!=' }
      ], 
      selectedActionType: {
        name: 'assign label'
      }, 
      actionTypes: [
        { name: 'assign label' }
      ], 
      triggerValue: '', 
      actionValue: ''
    }

    // this.onCsvExportClick = this.onCsvExportClick.bind(this)
    this.setOpen = this.setOpen.bind(this)
    this.setTriggerSelected = this.setTriggerSelected.bind(this)
    this.setOperatorSelected = this.setOperatorSelected.bind(this)
    this.setActionTypeSelected = this.setActionTypeSelected.bind(this)
    this.onTriggerValueChange = this.onTriggerValueChange.bind(this)
    this.onActionValueChange = this.onActionValueChange.bind(this)
    this.onRuleCreateClick = this.onRuleCreateClick.bind(this)
  }

  componentDidMount() {
    this.setState({
      open: false, 
      openInit: false
    })

    this.props.actions.getRules()
  }

  componentDidUpdate() {
    if(this.props.open && !this.state.openInit) {
      this.setState({
        openInit: true, 
        open: true 
      })
    }
  }

  setOpen() {
    if(this.state.open) {
      this.setState({
        open: !this.state.open, 
        openInit: false
      })

      this.props.onRuleManagerClose()
    }
  }

  setTriggerSelected(trigger) {
    this.setState({
      selectedTrigger: trigger
    })
  }

  setOperatorSelected(operator) {
    this.setState({
      selectedOperator: operator
    })
  }

  setActionTypeSelected(actionType) {
    this.setState({
      selectedActionType: actionType
    })
  }

  onTriggerValueChange(event) {
    this.setState({
      triggerValue: event.target.value
    })
  }

  onActionValueChange(event) {
    this.setState({ 
      actionValue: event.target.value
    })
  }

  onRuleCreateClick() {
    if(
      this.state.selectedTrigger.name &&
      this.state.selectedOperator.name && 
      this.state.selectedActionType.name && 
      this.state.actionValue !== '' && 
      this.state.triggerValue !== ''
    ) {
      this.props.actions.saveRule({
        triggerKey: this.state.selectedTrigger.name,
        triggerOperator: this.state.selectedOperator.name,
        triggerValue: this.state.triggerValue, 
        actionType: this.state.selectedActionType.name,
        actionValue: this.state.actionValue
      })
    }
  }

  render() {
    return (
      <RuleManagerComponent 
        open={this.state.open} 
        setOpen={this.setOpen}
        selectedTrigger={this.state.selectedTrigger}
        setTriggerSelected={this.setTriggerSelected}
        triggers={this.state.triggers}
        operators={this.state.operators}
        selectedOperator={this.state.selectedOperator}
        setOperatorSelected={this.setOperatorSelected}
        selectedActionType={this.state.selectedActionType}
        actionTypes={this.state.actionTypes}
        setActionTypeSelected={this.setActionTypeSelected}
        triggerValue={this.state.triggerValue}
        onTriggerValueChange={this.onTriggerValueChange}
        actionValue={this.state.actionValue}
        onActionValueChange={this.onActionValueChange}
        onRuleCreateClick={this.onRuleCreateClick}
        rules={this.props.rules}
      />
    )
  }

}


function mapStateToProps(state) {
  return {
    rules: state.rules
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, RuleManagerActions), dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RuleManager)
