export const lineSearchResults = (state = [], action) => {
  switch (action.type) {
    case 'SET_LINE_SEARCH_RESULTS':
      return action.lines
    default: 
      return state
  }
}

export const lineSearchResultsAmount = (state = 0, action) => {
  switch (action.type) {
    case 'SET_LINE_SEARCH_RESULTS_TOTAL_AMOUNT': 
      return action.amount
    default:
      return state
  }
}

export const saveReplacedValuesSuccess = (state = false, action) => {
  switch (action.type) {
    case 'SAVE_REPLACED_VALUES_SUCCESS':
      return action.status
    default:
      return state
  }
}

export const saveReplacedValuesError = (state = false, action) => {
  switch (action.type) {
    case 'SAVE_REPLACED_VALUES_ERROR':
      return action.status 
    default:
      return state 
  }
}