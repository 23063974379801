import React from 'react';
import Header from '../Header/header'

import Notification from '../containers/notification'
import LineCanvasDrawer from '../containers/lineCanvasDrawer'
import TextLineReviewLineItem from '../containers/textLineReviewLineItem'
import RuleManager from '../RuleManager/RuleManager'

import { Link, Redirect } from 'react-router-dom'
import StepsNavigator from '../components/stepsNavigator'
import FooterNavigator from '../components/footerNavigator'
import { HomeIcon } from '@heroicons/react/solid'
import UndoRedo from '../UndoRedo/UndoRedo';

import { ChevronUpIcon, ChevronDownIcon, CheckIcon } from '@heroicons/react/solid'

const steps = [
  { id: '01', name: 'Upload Images', href: '#', status: 'complete' },
  { id: '02', name: 'Review Paragraphs', href: '/paragraph-review/', status: 'complete' },
  { id: '03', name: 'Review Text', href: '/text-review/', status: 'current' },
  { id: '04', name: 'Export Text', href: '#', status: 'upcoming' }
]

const pages = [


  { name: 'Upload Images', href: '#', current: false, id: 1, status: 'complete' },
  { name: 'Review Paragraphs', href: '#', current: true, id: 2, status: 'complete',  },
  { name: 'Review Text', href: '#', current: false, id: 3, status: 'current',  },
  { name: 'Export Text', href: '#', current: false, id: 4, status: 'upcoming',  },
]

const TextLineReview = ({ 
  key,
  notebook, 
  onPrevClick, 
  onSkipClick, 
  onSubmitClick,
  saveError, 
  textLineSaveSuccess,
  paragraph,
  nextTextReviewParagraph,
  prevTextReviewParagraph,
  reviewStatus, 
  paragraphOutline,
  textReviewLines, 
  onTextLineChange, 
  imgPresentationWidth,
  paragraphId, 
  outlineCount, 
  outlineOffset, 
  onNextOutlinePageClick, 
  onPrevOutlinePageClick, 
  onImgFilterChange, 
  imgContrast, 
  imgHueRotate,
  imgBrightness, 
  annotations,
  lineCanvasDrawerBoxRef,
  lineCanvasDrawerBoxWidth, 
  onRuleManagerClick, 
  ruleManagerOpen, 
  onRuleManagerClose, 
  labels, 
  currentProjectId
}) => (
  <div className="bg-gray-100 min-h-screen fixed w-full" key={key}>
    {/* <div className="fixed left-0 top-0 w-full z-10"> */}
      {/* <Header disableNotebookButton={true}/> */}
      

    {/* </div> */}

    {/* <div className="fixed top-0 left-0 w-full h-full bg-white" aria-hidden="true" /> */}
    {/* <div className="fixed top-0 right-0 w-1/2 h-full bg-gray-50" aria-hidden="true" />             */}
    <nav className="w-full bg-white border-b-2 border-b-gray-100" aria-label="Breadcrumb">
        <ol role="list" className="max-w-screen-xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center mr-4">
              <Link to={`/${currentProjectId}`}>
                <img className="block h-12 w-auto" src="/logo.png" alt="Workflow"/>
                <span className="sr-only">Home</span>
              </Link>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name} className="flex">
              {page.status === 'complete' ? (
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 w-10 h-full text-gray-200"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <span className="flex-shrink-0 ml-4 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800 ">
                    <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
                  </span>
                  <a
                    href={page.href}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? 'page' : undefined}
                  >
                    {page.name}
                  </a>
                </div>
              ) : page.status === 'current' ? (
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 w-10 h-full text-gray-200"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <span className="flex-shrink-0 ml-4 w-8 h-8 flex items-center justify-center border-2 border-indigo-600 rounded-full">
                    <span className="text-indigo-600">{page.id}</span>
                  </span>
                  <a
                    href={page.href}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? 'page' : undefined}
                  >
                    {page.name}
                  </a>
                </div>
              ) : (
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 w-10 h-full text-gray-200"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <span className="flex-shrink-0 ml-4 w-8 h-8 flex items-center justify-center border-2 border-indigo-600 rounded-full">
                    <span className="text-indigo-600">{page.id}</span>
                  </span>
                  <a
                    href={page.href}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={page.current ? 'page' : undefined}
                  >
                    {page.name}
                  </a>
                </div>
              )}
            </li>
          ))}
        </ol>
    </nav>
    <div className="w-full flex flex-row bg-white">
      { /* Sidebar */ }
      <div className="xl:w-32 lg:w-24 lg:fixed lg:inset-y-0 lg:border-r lg:border-gray-200 mt-20 lg:pb-4 lg:bg-gray-100">
        {/* <div className="xl:flex-shrink-0 xl:w-32 xl:border-r xl:border-gray-200 bg-white"> */}
          <div className="bg-white relative overflow-auto h-full">
            <center>
              {paragraphOutline.hasPrevPage &&
                <span>
                  <a
                    onClick={onPrevOutlinePageClick}
                    className="relative inline-flex items-center px-2 py-2 rounded-t-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  </a><br/>
                </span>
              }
              <small><strong>{outlineOffset * outlineCount} - {(outlineOffset + 1) * outlineCount}</strong></small><br/>
              <small>of {paragraphOutline.totalParagraphAmount}</small>
            </center>
            {paragraphOutline.outline.map((imageOutline, index) =>
              <Link to={`/${currentProjectId}/text-line-review/${notebook.identifier}/${imageOutline.identifier}`} className="relative">
                <div 
                  key={index} 
                  className={`object-cover 
                    ${imageOutline.identifier === paragraphId ? 'bg-white' : 'bg-gray-100'}
                    ${imageOutline.identifier === paragraphId ? 'opacity-100' : 'opacity-75'}
                    hover:opacity-100 pt-4 pb-4 pl-3 pr-3 hover:bg-white
                    focus-within:ring-offset-2 focus-within:ring-indigo-500 relative
                  `}
                >
                  <img src={window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/' + imageOutline.thumb_image_url} 
                    // style={{maxWidth: 70}} 
                    className="rounded-t-md"
                  />
                  
                  {/* {imageOutline.checked_for_inheritance !== 1 && 
                    <span className="inline-flex items-center px-1 py-1 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 absolute" style={{ bottom: '1em', left: '0.5em' }}>
                      <svg className="-ml-1.5 mr-1.5 h-2 w-2 text-indigo-400" fill="currentColor" viewBox="0 0 8 8" style={{ marginLeft: 'auto', marginRight: 'auto'}}>
                        <circle cx={4} cy={4} r={3} />
                      </svg>
                    </span>
                  } */}

                  {imageOutline.checked_for_inheritance === 1 &&
                    <span className="inline-flex items-center px-1 py-1 rounded-full text-xs font-medium bg-green-600 text-green-800 absolute" style={{ bottom: '2em', left: '1.5em' }}>
                      <CheckIcon className="text-green-100 h-4 w-4"></CheckIcon>
                    </span>
                  }
                  <small>
                    <center>
                      <div className={`p-2 rounded-b-md
                        ${imageOutline.identifier === paragraphId ? 'bg-indigo-800 text-white' : 'bg-white'}
                      `}>
                        {imageOutline.offset}
                      </div>
                    </center>
                  </small>
                </div>
              </Link>
            )}
            <center>
              <small><strong>{outlineOffset * outlineCount} - {(outlineOffset + 1) * outlineCount}</strong></small><br/>
              <small>of {paragraphOutline.totalParagraphAmount}</small><br/>
              {paragraphOutline.hasNextPage &&
                <a
                  onClick={onNextOutlinePageClick}
                  className="relative inline-flex items-center px-2 py-2 rounded-b-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
                >
                  <span className="sr-only">Next</span>
                  <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </a>
              }
            </center>
          </div>
        {/* </div> */}
      </div>
      <div className="xl:ml-32 lg:ml-24 bg-white lg:mr-80 h-full">
        { /* Body */ }
        <div className="flex flex-row">
          <div className="ml-4 mr-4">
            <div className="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group block w-full rounded-lg mt-8 relative overflow-auto" style={{ 'height': 'calc(100vh - 320px)'}}>
              <div className="bg-white shadow overflow-hidden sm:rounded-md" ref={lineCanvasDrawerBoxRef}>
                <LineCanvasDrawer
                  imageUrl={paragraph.image_url} 
                  width={paragraph.img_width} 
                  height={paragraph.img_height} 
                  imgPresentationWidth={imgPresentationWidth}
                  lineCanvasDrawerBoxWidth={lineCanvasDrawerBoxWidth}
                  imgContrast={imgContrast}
                  imgBrightness={imgBrightness}
                />
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <div className="bg-white xl:right-80 lg:right-60 lg:fixed lg:inset-y-0 mt-20">
        <div className="group block w-full rounded-lg sm:rounded-md h-full relative overflow-auto">
          <div className="bg-white shadow">
            <ul role="list" className="divide-y divide-gray-200">
              {annotations.map((annotation, i) => (
                <TextLineReviewLineItem paragraphImg={paragraph.image_url} annotation={annotation} value={annotation.value} key={annotation.identifier}/>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {/* <div className="bg-gray-50 xl:w-80 lg:w-60 h-full lg:fixed right-0"> */}
      <div className="bg-gray-50 xl:w-80 lg:w-80 h-screen flex flex-col justify-between lg:fixed right-0">
        <div className="p-5">
          <div className="w-full float-left mb-4">
            <UndoRedo/>
          </div>
          <div className="w-full float-left">
            <h2 className="float-left text-sm font-medium text-gray-500">Rule Tags</h2>
            <a className="float-right text-indigo-700 text-sm hover:underline cursor-pointer" onClick={onRuleManagerClick}>+ Add Rule</a>
          </div>
          <ul role="list" className="mt-2 leading-8 float-none">
            {labels.map((label, labelIdx) => (
              <li className="inline" key={`label-${labelIdx}`}>
                <span
                  href="#"
                  className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5"
                >
                  <div className="absolute flex-shrink-0 flex items-center justify-center">
                    <span className="h-1.5 w-1.5 rounded-full bg-indigo-700" aria-hidden="true" />
                  </div>
                  <div className="ml-3.5 text-sm font-medium text-gray-900">{label['value']}</div>
                </span>{' '}
              </li>
            ))}
          </ul>
          <div className="w-full float-left pt-3 min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6 mt-8">
            {Object.keys(reviewStatus).length > 0 && 
              <span>
                <div className="flex items-center justify-between">
                  <div>
                    <span className="mb-2 text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
                      Review status
                    </span>
                  </div>
                  <div className="text-right">
                    <span className="text-xs font-semibold inline-block text-green-600">
                      {`${reviewStatus.checked_for_inheritance} / ${reviewStatus.total}`}
                    </span>
                  </div>
                </div>
                <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-green-200">
                  <div style={{ width: `${((reviewStatus.checked_for_inheritance / reviewStatus.total) * 100).toFixed(0)}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
                </div>
              </span>
            }
          </div>
        </div>
        <div className="p-5 pb-20">

          {prevTextReviewParagraph && prevTextReviewParagraph.identifier &&
            <Link to={`/${currentProjectId}/text-line-review/${notebook.identifier}/${prevTextReviewParagraph.identifier}`}>
              <button
                type="button"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={onPrevClick}
                tabIndex="-1"
              >
                Previous
                
              </button>
            </Link>
          }

          {nextTextReviewParagraph && nextTextReviewParagraph.identifier && 
            <Link to={`/${currentProjectId}/text-line-review/${notebook.identifier}/${nextTextReviewParagraph.identifier}`}>
              <button
                type="button"
                className="inline-flex items-center px-6 py-3 float-right border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={onSkipClick}
              >
                Skip
                {/* <kbd className="ml-2 text-right inline-flex items-center border border-gray-400 rounded px-2 text-sm font-sans font-medium text-gray-400">
                  ⌘H
                </kbd> */}
              </button>
            </Link>
          }

          {!nextTextReviewParagraph || !nextTextReviewParagraph.identifier && 
            <Link to={`/${currentProjectId}/notebook/${notebook.identifier}`}>
              <button
                type="button"
                className="inline-flex items-center px-6 py-3 float-right border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={onSkipClick}
              >
                Skip
                {/* <kbd className="ml-2 text-right inline-flex items-center border border-gray-400 rounded px-2 text-sm font-sans font-medium text-gray-400">
                  ⌘H
                </kbd> */}
              </button>
            </Link>
          }

          <button
            className="object-none object-bottom whitespace-nowrap inline-flex items-center justify-center  mt-4 mb-4 px-10 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            onClick={onSubmitClick}>
              {/* Finish Recipe */}
              Reviewed and Next
              <kbd className="ml-2 text-right inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-200">
              ⌘K
            </kbd>
          </button>

        </div>
      </div>

    </div>

    {saveError &&
      <Notification/>
    }

    {textLineSaveSuccess &&
      <Redirect to={`/${currentProjectId}/text-line-review/${notebook.identifier}/${nextTextReviewParagraph.identifier}`}/>
    }

    <RuleManager open={ruleManagerOpen} onRuleManagerClose={onRuleManagerClose}/>
  </div>
)

export default TextLineReview