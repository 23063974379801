import React, { useRef } from 'react';
import Header from '../Header/header'

import { ArrowRightIcon, ChevronDownIcon } from '@heroicons/react/outline'

const Input = ({ name, id, className, value, onChange, identifier }) => {
  const inputRef = React.useRef(null);
  const [selection, setSelection] = React.useState({ start: value.length, end: value.length, direction:'none' });

  React.useLayoutEffect(() => {
    inputRef.current.setSelectionRange(selection.start, selection.end, selection.direction);
  }, [selection.start, selection.end, selection.direction]);

  return (
    <input
      ref={inputRef}
      onSelect={(e) => {
        setSelection({
          start: value.length || 0,
          end: value.length || 0,
          direction: e.currentTarget.selectionDirection || 'none',
        });
      }}
      type='text'
      name={name}
      id={id}
      className={className}
      onChange={onChange}
      value={value}
      identifier={identifier}
    />
  );
}


const SearchAndReplaceComponent = ({
  lineSearchResults, 
  lineSearchResultsAmount, 
  cursor, 
  offset, 
  query, 
  onPreviewClick, 
  replacementValue,
  onReplaceChange, 
  onSingleValueChange, 
  onLineConfSortClick,
  onTextConfSortClick, 
  onSubmitClick, 
  replaceExactMatch, 
  onReplaceExactMatchChange, 
  onSingleSelectChange, 
  searchExactMatch, 
  onSearchExactMatchChange
}) => (
  <div className="bg-gray-100 min-h-screen">
    <Header/>
    <div className="flex-1 flex items-stretch overflow-hidden">
      <main className="flex-1 overflow-y-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10">
          <div className="flex">
            <h1 className="flex-1 text-2xl font-bold text-gray-900">Recurring Transcriptions</h1>
          </div>
          <div className="flex mt-8">
            <div className="mt-10 sm:mt-0">
              <div className="md:grid md:grid-cols-4 md:gap-6">
                <div className="mt-5 md:mt-0 md:col-span-3">
                  <div className="shadow overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5 bg-white sm:p-6">
                      <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-4 sm:col-span-4">
                          <label htmlFor="search-for" className="block text-sm font-medium text-gray-700">
                            Search for
                          </label>
                          <input
                            type="text"
                            name="search-for"
                            id="search-for"
                            value={query}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-72 shadow-sm sm:text-sm border-gray-300 rounded-md"
                            disabled
                          />
                        </div>

                        <div className="col-span-2 sm:col-span-2">
                          <center>
                            <button
                              tabIndex="-1"
                              type="button"
                              id="replace-icon"
                              className="inline-flex items-center px-2 py-2 border-2 border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white focus:outline-none"
                              style={{marginTop: 22}}
                            >
                              <ArrowRightIcon className="h-5 w-5 text-indigo-600"/> 
                            </button>
                          </center>
                        </div>

                        <div className="col-span-4 sm:col-span-4">
                          <label htmlFor="replace-with" className="block text-sm font-medium text-gray-700">
                            Replace with
                          </label>
                          <input
                            type="text"
                            name="replace-with"
                            id="replace-with"
                            value={replacementValue}
                            onChange={onReplaceChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-72 shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div className="col-span-2 sm:col-span-2">
                          <center>
                            <button
                              tabIndex="-1"
                              type="button"
                              id="replace-icon"
                              className="inline-flex items-center px-2 py-2 border-2 border-indigo-300 shadow-sm text-sm font-medium rounded-md text-indigo-700 bg-white hover:bg-indigo-50 focus:outline-none"
                              style={{marginTop: 22}}
                              onClick={onPreviewClick}
                            >
                              Preview
                            </button>
                          </center>
                        </div>
                      </div>
                      <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-4 sm:col-span-4">
                          <div className="relative flex items-start mt-4">
                            <div className="flex h-5 items-center">
                              <input type="checkbox"
                                id="replace-exact-match"
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                onChange={onReplaceExactMatchChange}
                                defaultChecked={replaceExactMatch}
                              />
                            </div>
                            <div className="ml-2 text-sm">
                              <label 
                                htmlFor="replace-exact-match" 
                                className="font-medium text-gray-700" 
                              >Replace exact match</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-4 sm:col-span-4">
                          <div className="relative flex items-start mt-4">
                            <div className="flex h-5 items-center">
                              <input type="checkbox"
                                id="search-exact-match"
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                onChange={onSearchExactMatchChange}
                                defaultChecked={searchExactMatch}
                              />
                            </div>
                            <div className="ml-2 text-sm">
                              <label 
                                htmlFor="search-exact-match" 
                                className="font-medium text-gray-700" 
                              >Search exact match</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="relative bg-indigo-100">
                      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                        <div className="pr-4 sm:text-left">
                          <p className="font-small text-black text-xs">
                            <span className="hidden md:inline">Showing results <strong>{cursor} - {offset}</strong> of <strong>{lineSearchResultsAmount}</strong></span>
                          </p>
                        </div>
                        <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
                          <button
                            type="button"
                            className="flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white"
                          >
                            <span className="sr-only">Dismiss</span>
                            {/* <XIcon className="h-6 w-6 text-white" aria-hidden="true" /> */}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex mt-8">
            <div className="mt-10 sm:mt-0">
              <div className="md:grid md:grid-cols-4 sm:grid-cols-1 md:gap-6">
                <div className="mt-5 md:mt-0 md:col-span-3">
                  <div className="shadow overflow-hidden sm:rounded-md">
                    <div className="bg-white">
                      <table className="min-w-full divide-y">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                              Selected
                            </th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                              Line image
                            </th>
                            {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Transcription
                            </th> */}
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Line confidence
                              <button className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300" onClick={onLineConfSortClick} sortby="conf_score">
                                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" sortby="conf_score"/>
                              </button>
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Text confidence
                              <button className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300" onClick={onTextConfSortClick} sortby="conf_score">
                                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" sortby="conf_score"/>
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {lineSearchResults.map((result, index) =>
                            <tr key={result['identifier']}>
                              <td className="relative whitespace-nowrap pl-6 pr-4 text-left text-sm font-medium sm:pr-6">
                                <div className="flex h-5 items-center">
                                  <input type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    onChange={onSingleSelectChange}
                                    defaultChecked={result['selected']}
                                    identifier={result['identifier']}
                                    tabindex="-1"
                                  />
                                </div>
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                <div className="flex items-center">
                                  <div className="flex-shrink-0 w-full md:w-2/3 lg:w-2/3">
                                    <img className="rounded-md" src={window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/' + result["image_url"]} alt="" />
                                  </div>
                                </div>
                                <div className="text-gray-900">
                                  <input
                                    type="text"
                                    name="value"
                                    id="value"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md w-full md:w-2/3 lg:w-2/3"
                                    value={result['value']}
                                    onChange={onSingleValueChange}
                                    identifier={result['identifier']}
                                  />
                                </div>
                              </td>
                              <td className="relative whitespace-nowrap pt-8 pl-3 pr-4 text-left text-sm font-medium sm:pr-6">
                                {result['line_conf_score'] > .9 &&
                                  result['line_conf_score']
                                }

                                {result['line_conf_score'] <= .9 && 
                                  <p className="text-red-600"><strong>{result['line_conf_score']}</strong></p>
                                }
                              </td>
                              <td className="relative whitespace-nowrap pt-8 pl-3 pr-4 text-left text-sm font-medium sm:pr-6">
                                {result['text_conf_score'] >= .1 &&
                                  result['text_conf_score']
                                }

                                {result['text_conf_score'] < .1 && 
                                  <p className="text-red-600"><strong>{result['text_conf_score']}</strong></p>
                                }
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex mt-8">
            <div className="mt-10 sm:mt-0">
              <div className="md:grid md:grid-cols-4 md:gap-6">
                <div className="mt-5 md:mt-0 md:col-span-3">
                  <div className="shadow overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5 bg-white sm:p-6">
                      <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-4 sm:col-span-4">
                          <button
                            type="button"
                            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Previous
                            {/* <kbd className="ml-2 text-right inline-flex items-center border border-gray-400 rounded px-2 text-sm font-sans font-medium text-gray-400">
                              ⌘H
                            </kbd> */}
                          </button>
                        </div>
                        <div className="col-span-8 sm:col-span-8">
                          <button
                            type="button"
                            className="float-right inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Next
                            {/* <kbd className="ml-2 text-right inline-flex items-center border border-gray-400 rounded px-2 text-sm font-sans font-medium text-gray-400">
                              ⌘H
                            </kbd> */}
                          </button>
                          <button
                            className="float-right mr-8 whitespace-nowrap inline-flex items-center justify-center px-10 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                            onClick={onSubmitClick}
                          >
                              Save Selected Images
                              <kbd className="ml-2 text-right inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-200">
                              ⌘K
                            </kbd>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </main>
    </div>
  </div>
)

export default SearchAndReplaceComponent