import React, { Component } from 'react';
import TextLineReviewComponent from './textLineReviewComponent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import * as textLineActions from './textLineActions'
import * as notebookActions from '../actions/notebook'

class TextLineReview extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isSubmitDisabled: false, 
      imgPresentationWidth: 700, 
      notebookId: this.props.match.params.notebookId,
      paragraphId: this.props.match.params.paragraphId, 
      imgScaleX: 0, 
      imgScaleY: 0, 
      outlineOffset: 0,
      outlineCount: 20, 
      imgContrast: 100,
      imgBrightness: 0,
      imgHueRotate: 0, 
      lineCanvasDrawerBoxWidth: 0, 
      ruleManagerOpen: false
    }

    this.onSubmitClick = this.onSubmitClick.bind(this)
    this.onNextOutlinePageClick = this.onNextOutlinePageClick.bind(this)
    this.onPrevOutlinePageClick = this.onPrevOutlinePageClick.bind(this)
    this.onImgFilterChange = this.onImgFilterChange.bind(this)
    this.onRuleManagerClick = this.onRuleManagerClick.bind(this)
    this.onRuleManagerClose = this.onRuleManagerClose.bind(this)

    this.lineCanvasDrawerBoxRef = React.createRef()
  }

  componentDidMount() {

    this.setState({
      lineCanvasDrawerBoxWidth: this.lineCanvasDrawerBoxRef.current.offsetWidth
    })

    document.addEventListener('keydown', e => {
      if (e.shiftKey && e.key === 13) {
        if(!this.state.isSubmitDisabled) {
          this.setState({ isSubmitDisabled: !this.state.isSubmitDisabled })
          
          this.props.actions.saveAnnotations({
            notebookId: this.state.notebookId,
            paragraphId: this.props.match.params.paragraphId
          })
        }
      }
    })

    if(this.props.match.params.paragraphId === 'next') {
      this.props.actions.fetchNextParagraph(this.props.match.params.notebookId, this.props.currentProjectId)
    } else {
      this.props.actions.fetchNotebook(this.props.match.params.notebookId)
      this.props.actions.fetchParagraph(this.props.match.params.notebookId, this.props.match.params.paragraphId, this.state.imgPresentationWidth)

      this.props.actions.fetchParagraphOutline(this.props.match.params.notebookId, 
          this.state.outlineOffset, this.state.outlineCount, this.props.match.params.paragraphId)
    }

    if(this.props.match.params.projectId && this.props.currentProjectId === "") {
      this.props.actions.setCurrentProjectId(this.props.match.params.projectId)
    }
  }

  componentDidUpdate() {
    const notebookId = this.props.match.params.notebookId
    const paragraphId = this.props.match.params.paragraphId

    if(this.state.notebookId !== notebookId || this.state.paragraphId !== paragraphId) {
      this.setState({
        isSubmitDisabled: false,
        notebookId: notebookId,
        paragraphId: paragraphId
      })
      
      this.props.actions.resetParagraphAnnotations()
      this.props.actions.resetSaveTextLineStatus()
      this.props.actions.fetchNotebook(this.props.match.params.notebookId)
      this.props.actions.fetchParagraph(notebookId, paragraphId, this.state.imgPresentationWidth)
      this.props.actions.fetchParagraphOutline(this.props.match.params.notebookId, 
        this.state.outlineOffset, this.state.outlineCount, this.props.match.params.paragraphId)
    }
  }

  onSubmitClick() {
    if(!this.state.isSubmitDisabled) {
      this.setState({ isSubmitDisabled: !this.state.isSubmitDisabled })

      this.props.actions.saveAnnotations({
        notebookId: this.state.notebookId,
        paragraphId: this.props.match.params.paragraphId
      })
    }
  }

  onNextOutlinePageClick() {
    this.props.actions.fetchParagraphOutline(this.props.match.params.notebookId, this.state.outlineOffset + 1, this.state.outlineCount)
    this.setState({
      outlineOffset: this.state.outlineOffset + 1
    })
  }

  onPrevOutlinePageClick() {
    this.props.actions.fetchParagraphOutline(this.props.match.params.notebookId, this.state.outlineOffset - 1, this.state.outlineCount)
    this.setState({
      outlineOffset: this.state.outlineOffset - 1
    })
  }

  onImgFilterChange(event) {
    const dataType = event.target.getAttribute('data-type')
    console.log({ dataType: dataType })
    this.setState({
      [dataType]: event.target.value
    })
  }

  onRuleManagerClick() {
    this.setState({ 
      ruleManagerOpen: true
    })
  }

  onRuleManagerClose() {
    this.setState({ 
      ruleManagerOpen: false
    })
  }

  render() {

    const annotations = _.orderBy(this.props.annotations, ['y'],['asc'])
    return (
      <TextLineReviewComponent
        paragraph={this.props.paragraph}
        nextTextReviewParagraph={this.props.nextLineReviewImage}
        prevTextReviewParagraph={this.props.prevLineReviewImage}
        onSubmitClick={this.onSubmitClick}
        imgPresentationWidth={this.state.imgPresentationWidth}
        notebook={this.props.notebook}
        paragraphOutline={this.props.paragraphOutline}
        imgContrast={this.state.imgContrast}
        imgHueRotate={this.state.imgHueRotate}
        imgBrightness={this.state.imgBrightness}
        onImgFilterChange={this.onImgFilterChange}
        onNextOutlinePageClick={this.onNextOutlinePageClick}
        onPrevOutlinePageClick={this.onPrevOutlinePageClick}
        outlineOffset={this.state.outlineOffset}
        outlineCount={this.state.outlineCount}
        paragraphId={this.state.paragraphId}
        annotations={annotations}
        reviewStatus={this.props.reviewStatus}
        lineCanvasDrawerBoxRef={this.lineCanvasDrawerBoxRef}
        lineCanvasDrawerBoxWidth={this.state.lineCanvasDrawerBoxWidth}
        textLineSaveSuccess={this.props.textLineSaveSuccess}
        onRuleManagerClick={this.onRuleManagerClick}
        ruleManagerOpen={this.state.ruleManagerOpen}
        onRuleManagerClose={this.onRuleManagerClose}
        labels={this.props.labels}
        currentProjectId={this.props.currentProjectId}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    notebook: state.notebookSingle,
    paragraph: state.textLineReviewParagraph,
    nextLineReviewImage: state.nextLineReviewImage,
    prevLineReviewImage: state.prevLineReviewImage, 
    textLineSaveSuccess: state.textLineSaveSuccess,
    lineSaveError: state.lineSaveError,
    lineImageLines: state.lineImageLines,
    reviewStatus: state.lineImageReviewStatus,
    paragraphOutline: state.textLineReviewParagraphOutline,
    annotations: state.textLineAnnotations.present, 
    labels: state.paragraphLabels, 
    currentProjectId: state.currentProjectId
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, textLineActions, notebookActions), dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TextLineReview)