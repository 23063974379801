import React from 'react';
import { Link } from 'react-router-dom'

import Header from '../Header/header'

import { CheckIcon, ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid'
// import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid'

const steps = [
  { id: '01', name: 'Upload Images', href: '', status: 'complete' },
  { id: '02', name: 'Review Paragraphs', href: 'paragraph-review', status: 'complete' },
  { id: '03', name: 'Review Text', href: 'text-line-review', status: 'current' },
  { id: '04', name: 'Export Text', href: 'data-export', status: 'upcoming' }
]

const stats = [
  { name: 'Number of deploys', value: '405' },
  { name: 'Average deploy time', value: '3.65', unit: 'mins' },
  { name: 'Number of servers', value: '3' },
  { name: 'Success rate', value: '98.5%' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const NotebookSingle = ({ 
  images, 
  notebook, 
  currentProjectId, 
  currentProjectName, 
  pageNumbers,
  currentPage, 
  onPageChange, 
  totalPages
}) => (
  <div className="bg-gray-100 min-h-screen">
    <Header notebook={notebook}/>

    <div className="flex-1 flex items-stretch overflow-hidden">
      <main className="flex-1 overflow-y-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10">

          <div className="flex">
            <h1 className="flex-1 text-2xl font-bold text-gray-900">{notebook.name}</h1>
            <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto" style={{display: 'none'}}>
              <Link to={`/${currentProjectId}/line-review/${notebook.identifier}`}>
                <button
                  className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-600"
                >
                  Review model predictions
                </button>
              </Link>
            </div>
          </div>

          {/* TODO: If one of the models is running, show infobox with that a model is running */}
          {currentProjectName === 'talens' &&
            <nav aria-label="Progress">
              <ol role="list" className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0 bg-white mt-8 ">
                {steps.map((step, stepIdx) => (
                  <Link to={step.href !== '' ? `/${currentProjectId}/${step.href}/${notebook.identifier}/next` : ''}>
                    <li key={step.name} className="relative md:flex-1 md:flex">
                      {step.status === 'complete' ? (
                        <span className="group flex items-center w-full mr-16">
                          <span className="px-6 py-4 flex items-center text-sm font-medium">
                            <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                              <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
                            </span>
                            <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                          </span>
                        </span>
                      ) : step.status === 'current' ? (
                        <span className="px-6 py-4 flex items-center text-sm font-medium mr-16" aria-current="step">
                          <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full">
                            <span className="text-indigo-600">{step.id}</span>
                          </span>
                          <span className="ml-4 text-sm font-medium text-indigo-600">{step.name}</span>
                        </span>
                      ) : (
                        <span className="group flex items-center mr-16">
                          <span className="px-6 py-4 flex items-center text-sm font-medium">
                            <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                              <span className="text-gray-500 group-hover:text-gray-900">{step.id}</span>
                            </span>
                            <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</span>
                          </span>
                        </span>
                      )}

                      {stepIdx !== steps.length - 1 ? (
                        <>
                          {/* Arrow separator for lg screens and up */}
                          <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                            <svg
                              className="h-full w-full text-gray-300"
                              viewBox="0 0 22 80"
                              fill="none"
                              preserveAspectRatio="none"
                            >
                              <path
                                d="M0 -2L20 40L0 82"
                                vectorEffect="non-scaling-stroke"
                                stroke="currentcolor"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </>
                      ) : null}
                    </li>
                  </Link>
                ))}
              </ol>
            </nav>
          }

          <section className="mt-8 pb-16" aria-labelledby="gallery-heading">

            <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
              {images.map((image) => (
                <Link to={`/${currentProjectId}/paragraph-review/${notebook.identifier}/${image.identifier}`}>
                  <li key={image.identifier} className="relative">
                    <div className="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden">
                      <img src={`/${image.image_url}`} alt="" className="group-hover:opacity-75 object-cover pointer-events-none" />
                      <button type="button" className="absolute inset-0">
                        <span className="sr-only">View details</span>
                      </button>
                    </div>
                    <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">{image.filename}</p>
                    <p className="block text-sm font-medium text-gray-500 pointer-events-none">
                      <span className={`${image.status !== 'published' ? 'bg-yellow-100 text-yellow-800' : 'bg-green-100 text-green-800'} inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium`}>
                        {image.status}
                      </span>
                    </p>
                  </li>
                </Link>
              ))}
            </ul>
          </section>  
          
          <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
            <div className="-mt-px flex w-0 flex-1">
              <a
                href="#!" 
                style={{ pointerEvents: currentPage === 1 ? 'none' : 'auto', opacity: currentPage === 1 ? 0.5 : 1 }}
                onClick={(e) => { e.preventDefault(); onPageChange(parseInt(currentPage) - 1); }}
                className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                <ArrowLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                Previous
              </a>
            </div>
            <div className="hidden md:-mt-px md:flex">
              {pageNumbers.map(page => (
                <a
                  href="#!"
                  key={page}
                  style={{ fontWeight: page === currentPage ? 'bold' : 'normal' }}
                  onClick={(e) => { e.preventDefault(); onPageChange(page); }}
                  className={page === currentPage ? "inline-flex items-center border-t-2 border-indigo-500 px-4 pt-4 text-sm font-medium text-indigo-600" : "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}
                >
                  {page}
                </a>
              ))}
              
              {/* Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" */}
              {/* <a
                href="#"
                className="inline-flex items-center border-t-2 border-indigo-500 px-4 pt-4 text-sm font-medium text-indigo-600"
                aria-current="page"
              >
                2
              </a> */}
            </div>
              
            <div className="-mt-px flex w-0 flex-1 justify-end">
              <a
                href="#!" 
                style={{ pointerEvents: currentPage === totalPages ? 'none' : 'auto', opacity: currentPage === totalPages ? 0.5 : 1 }}
                onClick={(e) => { e.preventDefault(); onPageChange(parseInt(currentPage) + 1); }}
                className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                Next
                <ArrowRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
              </a>
            </div>
          </nav>
        </div>
      </main>
    </div>
  </div>
)

export default NotebookSingle