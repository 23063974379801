import React, { Component } from 'react';
import LineReviewComponent from '../components/lineReview';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import * as reviewActions from '../actions/review';
import * as lineActions from '../actions/line'
import * as notebookActions from '../actions/notebook'

class LineReview extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isSubmitDisabled: false, 
      imgPresentationWidth: 700, 
      notebookId: this.props.match.params.notebookId,
      paragraphId: this.props.match.params.paragraphId, 
      imgRotationAngle: 0,
      imgScaleX: 0, 
      imgScaleY: 0, 
      outlineOffset: 0,
      outlineCount: 20
    }

    this.onSubmitClick = this.onSubmitClick.bind(this)
    this.handleAnnotationDelete = this.handleAnnotationDelete.bind(this)
    this.handleAllAnnotationsDelete = this.handleAllAnnotationsDelete.bind(this)
    this.handleAnnotationMouseOver = this.handleAnnotationMouseOver.bind(this)
    this.handleAnnotationMouseOut = this.handleAnnotationMouseOut.bind(this)
    this.toggleAnnotationActivation = this.toggleAnnotationActivation.bind(this)
    this.onImgRotationChange = this.onImgRotationChange.bind(this)
    this.onImgScaleXChange = this.onImgScaleXChange.bind(this)
    this.onImgScaleYChange = this.onImgScaleYChange.bind(this)
    this.onNextOutlinePageClick = this.onNextOutlinePageClick.bind(this)
    this.onPrevOutlinePageClick = this.onPrevOutlinePageClick.bind(this)
  }

  componentDidMount() {
    if(this.props.match.params.paragraphId === 'next') {
      this.props.actions.fetchNextLineReviewImage(this.props.match.params.notebookId)
    } else {
      this.props.actions.fetchNotebook(this.props.match.params.notebookId)

      // TODO
      this.props.actions.fetchLineReviewImage(this.props.match.params.notebookId, this.props.match.params.paragraphId, this.state.imgPresentationWidth)
      
      // TODO
      this.props.actions.fetchLineImagestatus(this.props.match.params.notebookId)
      // TODO
      this.props.actions.fetchLineImageOutline(this.props.match.params.notebookId, this.state.outlineOffset, this.state.outlineCount)
    }
  }

  componentDidUpdate() {
    const notebookId = this.props.match.params.notebookId
    const paragraphId = this.props.match.params.paragraphId

    if(this.state.notebookId !== notebookId || this.state.paragraphId !== paragraphId) {
      this.setState({
        isSubmitDisabled: false,
        notebookId: notebookId,
        paragraphId: paragraphId
      })

      this.props.actions.resetSaveLinesStatus(false)
      this.props.actions.fetchNotebook(this.props.match.params.notebookId)
      this.props.actions.fetchLineReviewImage(notebookId, paragraphId, this.state.imgPresentationWidth)
    } 
  }

  onSubmitClick() {
    if(!this.state.isSubmitDisabled) {
      this.setState({ isSubmitDisabled: !this.state.isSubmitDisabled })

      // TODO
      this.props.actions.uploadLineAnnotations(
        this.props.match.params.notebookId, 
        this.props.match.params.paragraphId,
        this.state.imgRotationAngle
      )
    }
  }

  onNextOutlinePageClick() {
    this.props.actions.fetchLineImageOutline(this.props.match.params.notebookId, this.state.outlineOffset + 1, this.state.outlineCount)
    this.setState({
      outlineOffset: this.state.outlineOffset + 1
    })
  }

  onPrevOutlinePageClick() {
    this.props.actions.fetchLineImageOutline(this.props.match.params.notebookId, this.state.outlineOffset - 1, this.state.outlineCount)
    this.setState({
      outlineOffset: this.state.outlineOffset - 1
    })
  }

  handleAnnotationDelete(identifier) {
    // TODO
    this.props.actions.deleteLineImageAnnotation(identifier)
  }

  handleAllAnnotationsDelete() {
    this.props.actions.deleteAllLineImageAnnotations()
  }

  handleAnnotationMouseOver(identifier) {
    // TODO
    this.props.actions.setLineAnnotationMouseOver(identifier, true)
  }

  handleAnnotationMouseOut(identifier) {
    // TODO
    this.props.actions.setLineAnnotationMouseOut(identifier, false)
  }

  toggleAnnotationActivation(identifier) {
    // TODO
    this.props.actions.toggleLineAnnotationActivation(identifier)
  }

  onImgRotationChange(event) {
    if(event.target.value !== this.state.imgRotationAngle) {
      this.setState({ imgRotationAngle: parseInt(event.target.value) })
    }
  }

  onImgScaleXChange(event) {
    if(event.target.value !== this.state.imgScaleX) {
      this.setState({ imgScaleX: parseInt(event.target.value) })
    }
  }

  onImgScaleYChange(event) {
    if(event.target.value !== this.state.imgScaleY) {
      this.setState({ imgScaleY: parseInt(event.target.value) })
    }
  }

  render() {
    console.log({ nextImageIdentifier: this.props.nextLineReviewImage, imageSaveSuccess: this.props.lineSaveSuccess })
    return (
      <LineReviewComponent
        image={this.props.lineReviewImage}
        nextLineReviewImage={this.props.nextLineReviewImage}
        prevLineReviewImage={this.props.prevLineReviewImage}
        onSubmitClick={this.onSubmitClick}
        imgPresentationWidth={this.state.imgPresentationWidth}
        notebook={this.props.notebook}
        lineSaveSuccess={this.props.lineSaveSuccess}
        lines={this.props.lineImageLines}
        handleAnnotationDelete={this.handleAnnotationDelete}
        handleAllAnnotationsDelete={this.handleAllAnnotationsDelete}
        handleAnnotationMouseOver={this.handleAnnotationMouseOver}
        handleAnnotationMouseOut={this.handleAnnotationMouseOut}
        toggleAnnotationActivation={this.toggleAnnotationActivation}
        reviewStatus={this.props.reviewStatus}
        lineImageOutline={this.props.lineImageOutline}
        imgRotationAngle={this.state.imgRotationAngle}
        onImgRotationChange={this.onImgRotationChange}
        imgScaleX={this.state.imgScaleX}
        onScaleXChange={this.onImgScaleXChange}
        imgScaleY={this.state.imgScaleY}
        onScaleYChange={this.onImgScaleYChange}
        outlineOffset={this.state.outlineOffset}
        outlineCount={this.state.outlineCount}
        onNextOutlinePageClick={this.onNextOutlinePageClick}
        onPrevOutlinePageClick={this.onPrevOutlinePageClick}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    notebook: state.notebookSingle,
    lineReviewImage: state.lineReviewImage,
    nextLineReviewImage: state.nextLineReviewImage,
    prevLineReviewImage: state.prevLineReviewImage, 
    lineSaveSuccess: state.lineSaveSuccess,
    lineSaveError: state.lineSaveError,
    lineImageLines: state.lineImageLines,
    reviewStatus: state.lineImageReviewStatus,
    lineImageOutline: state.lineImageOutline, 
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, lineActions, notebookActions), dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LineReview)