import React, { Component } from 'react';
import CreateProjectComponent from './createProjectComponent';
import * as projectActions from './createProjectActions'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class CreateProject extends Component {

  constructor(props) {
    super(props)

    this.state = {
      disabled: false, 
      projectTitle: ''
    }

    this.onSubmitProjectClick = this.onSubmitProjectClick.bind(this)
    this.onTitleChange = this.onTitleChange.bind(this)
  }

  componentDidMount() {
    
  }

  onSubmitProjectClick() {
    this.props.actions.saveProject({ title: this.state.projectTitle })
  }

  onTitleChange(e) {
    this.setState({ projectTitle: e.target.value })
  }

  render() {
    return (
      <CreateProjectComponent
        title={this.state.projectTitle}
        onTitleChange={this.onTitleChange}
        onSubmitProjectClick={this.onSubmitProjectClick}
        disabled={this.state.disabled}
      />
    )
  }

}


function mapStateToProps(state) {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, projectActions), dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProject)
