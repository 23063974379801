import { Link } from 'react-router-dom'

const FooterNavigator = ({ 
  prevReviewImageId,
  notebookId,
  reviewStatus,
  onSkipClick,
  onSubmitClick,
  onPrevClick,
  nextReviewImageId,
  nextReviewBlockLabel, 
  reviewBlock,
  nextReviewBlock, 
  currentProjectId
}) => (
  <footer className="bg-white shadow-sm border-t border-gray-200 fixed w-full bottom-0 left-0">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="px-4 flex items-center justify-between sm:px-0">
        <div className="-mt-px w-0 flex-1 flex text-md font-medium text-gray-800 pt-4 pb-4 hidden md:block">
          {prevReviewImageId &&
            <Link to={`/${currentProjectId}/${reviewBlock}/${notebookId}/${prevReviewImageId}`}>
              <button
                type="button"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={onPrevClick}
                tabIndex="-1"
              >
                Previous
                
              </button>
            </Link>
          }
        </div>
        {/* <div className="pt-3 relative min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6 hidden md:block">
          {Object.keys(reviewStatus).length > 0 && 
            <span>
              <div className="flex items-center justify-between">
                <div>
                  <span className="mb-2 text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
                    Review status
                  </span>
                </div>
                <div className="text-right">
                  <span className="text-xs font-semibold inline-block text-green-600">
                    {`${((reviewStatus.checked_for_inheritance / reviewStatus.total) * 100).toFixed(0)}%`}
                  </span>
                </div>
              </div>
              <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-green-200">
                <div style={{ width: `${((reviewStatus.checked_for_inheritance / reviewStatus.total) * 100).toFixed(0)}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
              </div>
            </span>
          }
        </div> */}
        <div className="-mt-px w-0 flex-1 flex justify-end pt-4 pb-4 ">
          {nextReviewImageId && 
            <button
              className="mr-4 ml-8 whitespace-nowrap inline-flex items-center justify-center px-10 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
              onClick={onSubmitClick}>
                Reviewed
                <kbd className="ml-2 text-right inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-200">
                ⌘K
              </kbd>
            </button>
          }

          {!nextReviewImageId && 
            <Link to={`/${currentProjectId}/${nextReviewBlock}/${notebookId}/next`}>
              <button
                className="mr-4 ml-8 whitespace-nowrap inline-flex items-center justify-center px-10 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                onClick={onSubmitClick}>
                  Reviewed
                  <kbd className="ml-2 text-right inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-200">
                  ⌘K
                </kbd>
              </button>
            </Link>
          }

          {nextReviewImageId && 
            <Link to={`/${currentProjectId}/${reviewBlock}/${notebookId}/${nextReviewImageId}`}>
              <button
                type="button"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={onSkipClick}
              >
                Skip
                {/* <kbd className="ml-2 text-right inline-flex items-center border border-gray-400 rounded px-2 text-sm font-sans font-medium text-gray-400">
                  ⌘H
                </kbd> */}
              </button>
            </Link>
          }

          {!nextReviewImageId && 
            <Link to={`/${currentProjectId}/notebook/${notebookId}`}>
              <button
                type="button"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={onSkipClick}
              >
                Skip
                {/* <kbd className="ml-2 text-right inline-flex items-center border border-gray-400 rounded px-2 text-sm font-sans font-medium text-gray-400">
                  ⌘H
                </kbd> */}
              </button>
            </Link>
          }
          
        </div>
      </div>
    </div>
  </footer>
) 

export default FooterNavigator