import * as types from '../actions/types'
import axios from 'axios'

export const saveRule = ({
  triggerKey,
  triggerOperator,
  triggerValue, 
  actionType,
  actionValue
}) => {
  return (dispatch, getState) => {
    const formData = new FormData()
    formData.append('trigger_key', triggerKey)
    formData.append('trigger_operator', triggerOperator)
    formData.append('trigger_value', triggerValue)
    formData.append('action_type', actionType)
    formData.append('action_value', actionValue)

    axios({
      method: 'POST',
      url: `/api/set-rule/`, 
      data: formData
    }).then((resp) => {
      dispatch({
        type: types.SET_RULES, 
        rules: resp.data.rules
      })
    }).catch((err) => {
      console.log('err: ', err)
    })
  }
}

export const getRules = () => {
  return (dispatch, getState) => {
    axios({
      method: 'GET',
      url: `/api/get-rules/`
    }).then((resp) => {
      dispatch({
        type: types.SET_RULES, 
        rules: resp.data.rules
      })
    })
  }
}