import React, { Component } from 'react';
import LoginComponent from '../components/login';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as loginActions from '../actions/login'

class Login extends Component {

  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: ''
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
  }

  handleSubmit() {
    this.props.actions.login({ email: this.state.email, password: this.state.password })
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value })
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value })
  }

  render() {
    return (
      <LoginComponent 
        onSubmitClick={this.handleSubmit}
        password={this.state.password}
        email={this.state.email}
        onPasswordChange={this.handlePasswordChange}
        onEmailChange={this.handleEmailChange}
        loginError={this.props.loginError}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    loginError: state.loginError
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, loginActions), dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)