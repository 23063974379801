export const recurringTranscriptionsFetchError = (state = false, action) => {
  switch (action.type) {
    case 'RECURRING_TRANSCRIPTIONS_FETCH_ERROR': 
      return action.errorState
    default: 
      return state
  }
}

export const recurringTranscriptions = (state = [], action) => {
  switch (action.type) {
    case 'RECURRING_TRANSCRIPTIONS':
      return action.transcriptions
    default: 
      return state
  }
}