import * as types from '../actions/types'
import axios from 'axios'
import _ from 'lodash'

export const saveProject = ({ title }) => {
  return (dispatch, getState) => {
    axios({
      method: 'POST', 
      url: '/api/project/new', 
      data: `name=${title}`
    }).then((resp) => {
      const identifier = resp.data.identifier
      window.location.replace(`/${identifier}`)
    })
  }
}

