import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as textLineActions from '../TextLineReview/textLineActions';
import _ from 'lodash'

import { XIcon } from '@heroicons/react/outline'
import { TrashIcon, ArrowCircleRightIcon } from '@heroicons/react/outline'
import { PlusIcon } from '@heroicons/react/solid';
import { CheckIcon } from '@heroicons/react/solid';

class TextLineReviewLineItem extends Component {
  constructor(props) {
    super(props)

    const randId = Math.random().toString(36).replace(/[^a-z]+/g, '')

    this.state = {
      canvasId: 'canvas-' + randId, 
      scaledX: 0, 
      scaledY: 0, 
      scaledWidth: 0, 
      scaledHeight: 0, 
      annotationHeight: 0, 
      annotationWidth: 0, 
      isAssigned: false
    }

    this.canvasBoxRef = React.createRef()
    this.onDeleteClick = this.onDeleteClick.bind(this)
    this.onValueChange = this.onValueChange.bind(this)
    this.drawCanvas = this.drawCanvas.bind(this)
    this.annotateOnFocus = this.annotateOnFocus.bind(this)
    this.annotateOnFocusOut = this.annotateOnFocusOut.bind(this)
    this.onAssignClick = this.onAssignClick.bind(this)
  }

  componentDidMount() {
    this.drawCanvas()
  }

  componentDidUpdate() {
    if(this.state.annotationHeight !== 0 && 
        this.state.annotationWidth !== 0 &&
        (parseInt(this.props.annotation.height) !== this.state.annotationHeight || 
         parseInt(this.props.annotation.width) !== this.state.annotationWidth)) {
      this.drawCanvas()
    }
  }

  drawCanvas() {
    const canvas = document.getElementById(this.state.canvasId)
    const ctx = canvas.getContext('2d');
    const presentationWidth = 700
    // const sidebarWidth = 450
    const sidebarWidth = this.canvasBoxRef.current.offsetWidth

    const origHeight = parseInt(this.props.annotation.height * this.props.annotation.imgOriginalHeight / 
                          (this.props.annotation.imgOriginalHeight * presentationWidth /
                          this.props.annotation.imgOriginalWidth))
    const origWidth = parseInt(this.props.annotation.width * this.props.annotation.imgOriginalWidth /
                          presentationWidth)

    this.setState({
      origX: parseInt(this.props.annotation.x * this.props.annotation.imgOriginalWidth / 
                presentationWidth), 
      origY: parseInt(this.props.annotation.y * this.props.annotation.imgOriginalHeight / 
                (this.props.annotation.imgOriginalHeight * presentationWidth /
                this.props.annotation.imgOriginalWidth)), 
      origWidth: origWidth, 
      origHeight: origHeight,
      sidebarWidth: sidebarWidth,
      sidebarHeight: parseInt(origHeight * sidebarWidth / origWidth),
      annotationHeight: parseInt(this.props.annotation.height),
      annotationWidth: parseInt(this.props.annotation.width)
    })
  
    let img = new Image();
    img.onload = () => {
      ctx.drawImage(
        img,
        this.state.origX, this.state.origY, // Start at X/Y pixels from the left and the top of the image (crop),
        this.state.origWidth, this.state.origHeight, // "Get" a `50 * 50` (w * h) area from the source image (crop),
        0, 0,     // Place the result at 0, 0 in the canvas,
        this.state.sidebarWidth, this.state.sidebarHeight // With as width / height: 100 * 100 (scale)
      )
    }
    img.src = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/' + this.props.paragraphImg
  }

  onDeleteClick() {
    this.props.actions.deleteTextLineImageAnnotation(this.props.annotation.identifier)
  }

  onValueChange(e) {
    this.props.actions.setTextLineValue(this.props.annotation.identifier, e.target.value)
  }

  annotateOnFocus() {
    this.props.actions.annotateBlock(true)
  }

  annotateOnFocusOut() {
    this.props.actions.annotateBlock(false)
  }

  onAssignClick() {
    // Mock user identifier from "Rika"
    const assignTo = "acbcd9de-8484-434b-9e14-971504bd90b9"

    this.setState({ isAssigned: !this.state.isAssigned }, () => {
      this.props.actions.setTextLineAssignment(this.props.annotation.identifier, assignTo, this.state.isAssigned)
    })
    
  }

  render() {
    return (
      <div className="flex">
        <div className="flex-none ">
          <div ref={this.canvasBoxRef}>
            <canvas 
              id={this.state.canvasId} 
              width={this.state.sidebarWidth} height={this.state.sidebarHeight}>  
            </canvas>
          </div>
          <div className="flex justify-start">
            <input
              type="text"
              name="text"
              id="text"
              className="block w-full sm:text-sm p-2 focus:outline-none border-indigo-100 border-b-2 border-t-0 border-r-0 border-l-0 focus:ring-0 outline-none"
              placeholder=""
              value={this.props.value}
              onChange={this.onValueChange}
              onBlur={this.annotateOnFocusOut}
              onFocus={this.annotateOnFocus}
            />
          </div>
          <div className="flex justify-between">
            <div className="px-2 pt-1 pb-3 flex">
              {this.props.annotation.content_review_status == 'published' &&
                <span className="my-1 mr-2 px-1 py-1 rounded-full bg-green-600 text-green-800 h-6 w-6">
                  <CheckIcon className="text-green-100 h-4 w-4"></CheckIcon>
                </span>
              }

              {(!this.props.annotation.assigned_to && !this.state.isAssigned) &&
                <span>
                  {this.props.reviewAssignees.map((assignee) => (
                    <button
                      tabIndex="-1"
                      type="button"
                      className="inline-flex items-center border-none p-1 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400"
                      onClick={this.onAssignClick}
                    >
                      <ArrowCircleRightIcon className="h-5 w-5 text-gray-400"/> 
                      <span className="text-gray-400">{assignee.name}</span>
                      
                    </button>
                  ))}
                </span>
              }

              {(this.props.annotation.assigned_to || this.state.isAssigned ) &&
                <button
                  tabIndex="-1"
                  type="button"
                  className="inline-flex items-center border-none p-1 shadow-sm text-sm font-medium rounded-md text-white bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400"
                  onClick={this.onAssignClick}
                >
                  <CheckIcon className="h-5 w-5 text-white"/> 
                  <span className="text-white">Assigned</span>
                </button>
              }
            </div>
            <div className="px-2 pt-1 pb-3">
              <button
                tabIndex="-1"
                type="button"
                className="inline-flex items-center border-none p-1 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                onClick={this.onDeleteClick}
              >
                <TrashIcon className="h-5 w-5 text-gray-400"/> 
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    reviewAssignees: state.reviewAssignees
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, textLineActions), dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TextLineReviewLineItem)