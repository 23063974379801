import React, { Component } from 'react';
import NotebookListComponent from '../components/notebookList';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as notebookActions from '../actions/notebook'
import _ from 'lodash'

class NotebookList extends Component {

  constructor(props) {
    super(props)
    
    this.state = {}

    this.onModelRetrainClick = this.onModelRetrainClick.bind(this)
  }

  componentDidMount() {
    if(!this.props.match.params.projectId && !this.props.noProjectsFoundStatus) {
      this.props.actions.fetchLatestProjectId()
    } else if(this.props.match.params.projectId && this.props.currentProjectId === "") {
      this.props.actions.setCurrentProjectId(this.props.match.params.projectId)
      this.props.actions.fetchNotebooks()
      this.props.actions.fetchProjectStats()
      this.props.actions.fetchModelRetrainStatus()
    } else if(this.props.match.params.projectId && this.props.currentProjectId !== "") {
      this.props.actions.fetchNotebooks()
      this.props.actions.fetchProjectStats()
      this.props.actions.fetchModelRetrainStatus()
    }
  }

  onModelRetrainClick() {
    this.props.actions.initModelRetrain()
  }

  render() {
    const currentProjectName = _.filter(this.props.projects, { identifier: this.props.currentProjectId})

    return (
      <NotebookListComponent 
        notebooks={this.props.notebooks}
        projectStats={this.props.projectStats}
        noProjectsFoundStatus={this.props.noProjectsFoundStatus}
        currentProjectId={this.props.currentProjectId}
        onModelRetrainClick={this.onModelRetrainClick}
        modelTrainStatus={this.props.modelTrainStatus}
        currentProjectName={currentProjectName.length > 0 ? currentProjectName[0]['name'].toLowerCase() : []}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    notebooks: state.notebooks, 
    projectStats: state.projectStats, 
    noProjectsFoundStatus: state.noProjectsFound,
    currentProjectId: state.currentProjectId, 
    modelTrainStatus: state.modelTrainStatus, 
    projects: state.projects
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, notebookActions), dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotebookList)