import { combineReducers } from 'redux'
import * as upload from './upload'
import * as notebook from './notebook'
import * as paragraph from '../ParagraphComponent/paragraphReducer'
import * as login from './login'
import * as line from './line'
import * as text from './text'
import * as textLine from '../TextLineReview/textLineReducer'
import * as recurringTranscriptions from '../RecurringTranscriptionsWidget/RecurringTranscriptionsWidgetReducer'
import * as searchAndReplace from '../SearchAndReplace/SearchAndReplaceReducer'
import * as ruleManager from '../RuleManager/RuleManagerReducer'
import * as header from '../Header/headerReducer'
import * as drawingCanvas from '../DrawingCanvas/canvasReducer'

const combinedReducers = combineReducers(Object.assign({}, upload, notebook, paragraph, login, line, 
  text, textLine, recurringTranscriptions, searchAndReplace, ruleManager, header, drawingCanvas));

export default combinedReducers