import * as types from '../actions/types'
import axios from 'axios'
import _ from 'lodash'

export const fetchParagraph = (paragraphId) => {
  return (dispatch, getState) => {

    dispatch({
      type: 'RESET_PARAGRAPH_STATE'
    })

    dispatch({
      type: 'RESET_TABLE_STATE'
    })

    axios({
      method: 'GET', 
      url: `/api/paragraph/${paragraphId}`
    }).then((resp) => {
      let paragraph = _.pick(resp.data.paragraph, ['image_url', 'notebook_id', 'paragraph_id', 'status', 'year', 'img_width', 'img_height', 'name', 'comment'])

      // const content = Object.values(_.groupBy(resp.data.paragraph.content, 'line_offset'))
      const content = resp.data.paragraph.content

      dispatch({ 
        type: types.SET_SINGLE_PARAGRAPH, 
        paragraph: paragraph, 
        comment: resp.data.paragraph.comment, 
        content: content,
        name: resp.data.paragraph.name
      })

      dispatch({ 
        type: types.SET_TABLE_CONTENT, 
        content: content
      })

      dispatch({
        type: types.SET_PARAGRAPH_TITLE,
        value: paragraph.name ? paragraph.name : ''
      })

      dispatch({
        type: types.SET_PARAGRAPH_COMMENT,
        value: paragraph.comment ? paragraph.comment : ''
      })
    })
  }
}

export const uploadParagraphAnnotations = (notebookId, notebookImageId, imgStageWidth) => {
  return (dispatch, getState) => {
    const annotations = getState().paragraphAnnotations
    const polygons = getState().polygons

    const formData = new FormData()
    formData.append('annotations', JSON.stringify(annotations))
    formData.append('polygons', JSON.stringify(polygons))
    formData.append('notebookImageId', notebookImageId)
    formData.append('imgStageWidth', imgStageWidth)

    axios({
      method: 'POST',
      url: `/api/notebook-image-annotations/${notebookId}`,
      data: formData
    }).then((resp) => {
      dispatch({
        type: types.SAVE_PARAGRAPH_SUCCESS, 
        value: true
      })
    }).catch((err) => {
      console.log(err)
      dispatch({
        type: types.SAVE_PARAGRAPH_ERROR,
        value: 'Something went wrong'
      })
    })
  }
}

export const saveParagraph = (paragraphId, notebookId) => {
  return (dispatch, getState) => {
    const updatedTable = getState().tableContent
    const changelog = getState().changelog
    const paragraphTitle = getState().paragraphTitle
    const paragraphComment = getState().paragraphComment

    const formData = new FormData()
    formData.append('updated_content', JSON.stringify(updatedTable))
    formData.append('paragraph_id', paragraphId)
    formData.append('notebook_id', notebookId)
    formData.append('name', paragraphTitle)
    formData.append('comment', paragraphComment)
    formData.append('changelog', JSON.stringify(changelog))

    axios({
      method: 'POST', 
      url: `/api/paragraph/${paragraphId}`, 
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    }).then((resp) => {
      dispatch({
        type: 'SAVE_PARAGRAPH_SUCCESS', 
        value: true
      })
    }).catch((err) => {
      dispatch({
        type: 'SAVE_PARAGRAPH_ERROR', 
        value: 'Something went wrong'
      })
    })
  }
}

export const resetSaveParagraphStatus = () => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SAVE_PARAGRAPH_SUCCESS', 
      value: false
    })
  }
}

export const resetParagraphState = () => {
  return (dispatch, getState) => {
    dispatch({
      type: 'RESET_PARAGRAPH_STATE'
    })
  }
}

export const deletePolygonLine = (updatedLines) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'CANVAS_POLYGON_SET_LINES', 
      lines: updatedLines
    })
  }
}

// DrawingCanvas related 