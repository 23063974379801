import * as types from '../actions/types'
import axios from 'axios'
import _ from 'lodash'

export const fetchSearchResults = (query, cursor, offset, lineConfSortBy, textConfSortBy, searchExactMatch) => {
  return (dispatch, getState) => {

    let url = `/api/line-search/${cursor}/${offset}?query=${query}`

    if(lineConfSortBy !== '') {
      url = url + `&sortby=line_conf_score&sortmethod=${lineConfSortBy}`

      if(textConfSortBy !== '') {
        url = url + `&secondarysortyby=text_conf_score&secondarysortmethod=${textConfSortBy}`
      }
    } else if(lineConfSortBy === '' && textConfSortBy !== '') {
      url = url + `&sortby=text_conf_score&sortmethod=${textConfSortBy}`
    }

    if(searchExactMatch) {
      url = url + '&search_exact_match=1'
    } else {
      url = url + '&search_exact_match=0'
    }

    axios({
      method: 'GET', 
      url: url
    }).then((resp) => {
      dispatch({
        type: 'SET_LINE_SEARCH_RESULTS', 
        lines: resp.data.lines
      })

      dispatch({
        type: 'SET_LINE_SEARCH_RESULTS_TOTAL_AMOUNT', 
        amount: resp.data.amount
      })
    }).catch((err) => {
      console.log('search results errpr: ', err)
    })
  }
}

export const saveReplacedValues = () => {
  return (dispatch, getState) => {
    const lineSearchResults = _.filter(getState().lineSearchResults, { 'selected': 1 })

    console.log({ lineSearchResults: lineSearchResults })
    
    const formData = new FormData()
    formData.append('annotations', JSON.stringify(lineSearchResults))

    axios({
      method: 'POST',
      url: `/api/line-annotations/`, 
      data: formData
    }).then((resp) => {
      dispatch({
        type: 'SAVE_REPLACED_VALUES_SUCCESS', 
        status: true
      })
    }).catch((err) => {
      console.log('err: ', err)
      dispatch({
        type: 'SAVE_REPLACED_VALUES_ERROR',
        status: true
      })
    })
  }
}

export const resetSaveReplacedValuesSuccess = () => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SAVE_REPLACED_VALUES_SUCCESS',
      status: false
    })
  }
}

export const replaceValues = (oldValue, newValue, replaceExactMatch = true) => {
  return (dispatch, getState) => {
    let lineSearchResults = getState().lineSearchResults
    console.log({ replaceExactMatch: replaceExactMatch })
    const updatedLines = _.map(lineSearchResults, (line) => {

      let updatedValue = line.value.replace(oldValue, newValue)

      if(!replaceExactMatch) {
        updatedValue = newValue
      }

      return { 
        identifier: line.identifier, 
        image_url: line.image_url, 
        value: updatedValue, 
        conf_score: line.conf_score, 
        line_conf_score: line.line_conf_score, 
        text_conf_score: line.text_conf_score, 
        selected: line.selected
      }
    })

    dispatch({
      type: 'SET_LINE_SEARCH_RESULTS', 
      lines: updatedLines
    })
  }
}

export const replaceValue = (newValue, identifier) => {
  return (dispatch, getState) => {
    let lineSearchResults = getState().lineSearchResults

    const updatedLines = _.map(lineSearchResults, (line) => {
      if(line.identifier === identifier) {
        return { 
          identifier: line.identifier, 
          value: newValue, 
          image_url: line.image_url, 
          conf_score: line.conf_score, 
          selected: line.selected
        }
      } else {
        return line 
      }
    })

    dispatch({
      type: 'SET_LINE_SEARCH_RESULTS',
      lines: updatedLines
    })
  }
}

export const toggleSingleSelect = (identifier) => {
  return (dispatch, getState) => {
    let lineSearchResults = getState().lineSearchResults

    const updatedLines = _.map(lineSearchResults, (line) => {
      if(line.identifier === identifier) {
        return { 
          identifier: line.identifier, 
          value: line.value, 
          image_url: line.image_url, 
          conf_score: line.conf_score, 
          selected: !line.selected
        }
      } else {
        return line 
      }
    })

    dispatch({
      type: 'SET_LINE_SEARCH_RESULTS',
      lines: updatedLines
    })
  }
}