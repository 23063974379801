import * as types from './types'
import axios from 'axios'

export const login = ({ email, password }) => {
  return (dispatch, getState) => {

    axios({
      method: 'POST', 
      url: '/api/login', 
      data: `email=${email}&password=${password}`
    }).then((resp) => {
      dispatch({
        type: types.USER_LOGGED_IN,
        accessToken: resp.data.accessToken,
        refreshToken: resp.data.refreshToken,
        identifier: resp.data.email
      })
    }).catch((err) => {
      console.log({ err: err})
      if(err.response.status === 401) {
        dispatch({
          type: types.USER_LOGIN_ERROR, 
          error: 'Wrong email or password'
        })
      } else {
        dispatch({
          type: types.USER_LOGIN_ERROR, 
          error: 'Something went wrong. Try again later'
        })
      }
    })
  }
}