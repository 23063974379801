import * as types from '../actions/types'
import axios from 'axios'

export const fetchProjects = () => {
  return (dispatch, getState) => {
    axios({
      method: 'GET',
      url: `/api/projects`
    }).then((resp) => {
      dispatch({
        type: types.SET_AVAILABLE_PROJECTS,
        projects: resp.data.projects
      })
    })
  }
}