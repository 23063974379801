import * as types from './types'
import axios from 'axios'

export const setAcceptedFiles = (acceptedFiles) => {
  return (dispatch, getState) => {
    dispatch({ 
      type: types.SET_ACCEPTED_UPLOAD_FILES,
      files: acceptedFiles
    })
  }
}

export const removeAcceptedFiles = () => {
  return (dispatch, getState) => {
    dispatch({ 
      type: types.REMOVE_ACCEPTED_UPLOAD_FILES
    })
  }
}

export const setNotebookTitle = (input) => {
  return (dispatch, getState) => {
    dispatch({
      type: types.SET_NEW_NOTEBOOK_TITLE,
      title: input
    })
  }
}

export const uploadNotebook = (needsParagraphDetection = false) => {
  return (dispatch, getState) => {

    dispatch({
      type: types.SET_IMAGE_UPLOAD_STATUS,
      total: getState().acceptedFiles.length, 
      uploaded: 0
    })

    // upload the title, obtain a notebook ID
    axios({
      method: 'POST', 
      url: '/api/notebook/new', 
      data: `name=${getState().notebookTitle}&project_id=${getState().currentProjectId}`
    }).then((resp) => {
      const notebookUuid = resp.data.notebook_uuid
      let firstNotebookImageUuid = false

      let i = 0
      getState().acceptedFiles.forEach((file) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('notebookUuid', notebookUuid)
        formData.append('paragraphDetection', needsParagraphDetection)

        axios({
          method: 'POST',
          url: '/api/notebook/upload-photo',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          data: formData
        }).then((resp) => {
          i = i + 1

          if(!firstNotebookImageUuid) {
            firstNotebookImageUuid = resp.data.uuid
          }

          const filesAmount = getState().acceptedFiles.length

          dispatch({
            type: types.SET_IMAGE_UPLOAD_STATUS,
            total: filesAmount, 
            uploaded: i
          })

          if(i === filesAmount) {
            window.location.replace(`/paragraph-review/${notebookUuid}/${firstNotebookImageUuid}`)
          }
        })
      })

      
    })

  }
}