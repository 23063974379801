import * as types from '../actions/types'
import axios from 'axios'

export const fetchRecurrences = () => {
  return (dispatch, getState) => {
    axios({
      method: 'GET', 
      url: `/api/recurring-transcriptions`
    }).then((resp) => {
      dispatch({
        type: 'RECURRING_TRANSCRIPTIONS', 
        transcriptions: resp.data.recurring_transcriptions
      })

      dispatch({
        type: 'RECURRING_TRANSCRIPTIONS_FETCH_ERROR', 
        value: 'Recurring transcriptions found.', 
        errorState: false
      })
    }).catch((err) => {
      dispatch({
        type: 'RECURRING_TRANSCRIPTIONS_FETCH_ERROR', 
        value: 'Recurring transcriptions not found.', 
        errorState: true
      })
    })
  }
}