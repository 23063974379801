import _ from 'lodash';
import undoable, { groupByActionTypes, excludeAction } from 'redux-undo';

export const textLineReviewParagraph = (state = {}, action) => {
  switch(action.type) {
    case 'SET_TEXT_LINE_REVIEW_PARAGRAPH':
      return action.paragraph
    default:
      return state
  }
}

export const nextLineReviewImage = (state = {}, action) => {
  switch(action.type) {
    case 'SET_NEXT_TEXT_LINE_REVIEW_PARAGRAPH':
      return action.lineImage
    default:
      return state
  }
}

export const prevLineReviewImage = (state = {}, action) => {
  switch(action.type) {
    case 'SET_PREV_TEXT_LINE_REVIEW_PARAGRAPH':
      return action.lineImage
    default:
      return state
  }
}

export const textLineSaveSuccess = (state = false, action) => {
  switch(action.type) {
    case 'SAVE_TEXT_LINES_SUCCESS':
      return action.value
    case 'RESET_TEXT_LINES_SUCCESS':
      return action.value
    default:
      return state
  }
}

export const paragraphLabels = (state = [], action) => {
  switch(action.type) {
    case 'SET_PARAGRAPH_LABELS': 
      return action.labels
    default: 
      return state
  }
}

export const reviewAssignees = (state = [], action) => {
  switch(action.type) {
    case 'SET_REVIEW_ASSIGNEES': 
      return action.assignees
    default: 
      return state
  }
}

// export const lineSaveError = (state = {}, action) => {
//   switch(action.type) {
//     case
//     default:
//       return state
//   }
// }

// export const lineImageLines = (state = [], action) => {
//   switch(action.type) {
//     case 'SET_TEXT_LINE_ANNOTATIONS':
//       return action.annotations
//     default:
//       return state
//   }
// }

export const lineImageReviewStatus = (state = {}, action) => {
  switch(action.type) {
    case 'SET_PARAGRAPH_INHERITANCE_PROGRESS_STATUS':
      return action.status
    default:
      return state
  }
}

export const textLineReviewParagraphOutline = (state = { outline: [], hasPrevPage: false, hasNextPage: false, totalParagraphAmount: 0 }, action) => {
  switch(action.type) {
    case 'SET_TEXT_LINE_REVIEW_PARAGRAPH_OUTLINE':
      return { 
        outline: action.outline, 
        hasPrevPage: action.hasPrevPage, 
        hasNextPage: action.hasNextPage,
        totalParagraphAmount: action.totalParagraphAmount 
      }
    default:
      return state
  }
}

export const hasAnnotateBlock = (state = false, action) => {
  switch(action.type) {
    case 'TOGGLE_ANNOTATE_BLOCK':
      return action.hasAnnotateBlock
    default:
      return state
  }
}

const textLineAnnotationsBase = (state = [], action) => {
  switch (action.type) {
    case 'SET_TEXT_LINE_ANNOTATIONS':
      return action.annotations
    case 'DELETE_TEXT_LINE_ANNOTATION':
      const newAnnotations =  _.filter(action.annotations, function(o) { 
        return o.identifier != action.identifier; 
     });

     return newAnnotations
    case 'DELETE_ALL_TEXT_LINE_ANNOTATIONS':
      return []
    case 'SET_TEXT_LINE_ANNOTATION_MOUSE_OVER':
      const annotationsMouseOver = _.map(action.annotations, function(o) {
        if(o.identifier == action.identifier) {
          o.mouseover = true;
        }

        return o;
      })

      return annotationsMouseOver
    case 'SET_TEXT_LINE_ANNOTATION_MOUSE_OUT': 
      const annotationsMouseOut = _.map(action.annotations, function(o) {
        if(o.identifier == action.identifier) {
          o.mouseover = false;
        }

        return o;
      })

      return annotationsMouseOut
    case 'SET_TEXT_LINE_VALUE':
      const updatedAnnotations = action.annotations
      // const index = _.findIndex(action.annotations, {identifier: action.identifier});
      
      // let item = action.annotations[index]
      // item.value = action.value 

      // updatedAnnotations.splice(index, 1, item);

      return updatedAnnotations
    default:
      return state
  }
}

export const textLineAnnotations = undoable(textLineAnnotationsBase, { 
  ignoreInitialState: true,
  // groupBy: function groupByActions(action, currentState, previousHistory) {
  //   return action.type !== 'SET_TEXT_LINE_VALUE'
  // }, 
  // filter: function filterActions(action, currentState, previousHistory) {
  //   console.log({ action: action.type })
  //   return action.type !== 'SET_TEXT_LINE_VALUE'; // only add to history if action is not 
  // }
})