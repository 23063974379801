import React from 'react';

const RecurringTranscriptionsWidgetComponent = ({ 
  fetchError, 
  recurringTranscriptions
}) => (
  <div className="relative overflow-hidden rounded-lg bg-white shadow">
    <div className="px-4 py-8 sm:px-6 pb-8">
      <h3 className="text-lg leading-6 font-medium text-gray-900">Recurring transcriptions</h3>
      <p className="mt-1 max-w-2xl text-sm text-gray-500">Bulk review recurring Rosetta transcriptions as they can contain the same mistakes.</p>
    </div>
    <div className="border-t border-gray-200">
      {fetchError && 
        <div className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6">Recurring transcriptions cannot be loaded.</div>
      }

      {!fetchError &&
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Appearances
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Value
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {recurringTranscriptions.map((transcription, index) => 
              <tr key={`recurring-transcription-${index}`}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6">
                  {transcription.occurences}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                  {transcription.value}
                </td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <a href={`/search-and-replace/0/100/${transcription.value}`} className="text-indigo-600 hover:text-indigo-900">
                    Review<span className="sr-only">, maltijd 9 u</span>
                  </a>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      }
    </div>
  </div>
)

export default RecurringTranscriptionsWidgetComponent