import { CSVLink} from 'react-csv';
import React from 'react';
import { Link } from 'react-router-dom'

import Header from '../Header/header'

const DataExport = ({ 
  onTitleChange, 
  title, 
  onExportDataClick, 
  disabled, 
  csvLink, 
  data
}) => (
  <div className="bg-gray-100 min-h-screen">
    <Header/>
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:p-6">
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Data Export</h3>
            <p className="mt-1 text-sm text-gray-500">
              Export data
            </p>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form className="space-y-6" action="#" method="POST">
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  {/* <label for="notebook_name" className="block text-sm font-medium text-gray-700">Project name</label> */}
                  {/* <input type="text" value={title} onChange={onTitleChange} name="notebook_name" id="notebook_name" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/> */}
                </div>
              </div>

            </form>

          </div>

        </div>
      </div>

      <div className="flex justify-end sm:p-6">
        <Link to="/">
          <button type="button" className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Cancel
          </button>
        </Link>
        <button type="submit" disabled={disabled} onClick={onExportDataClick} className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          {disabled ? 'Downloading...' : 'Download'}
        </button>
        <CSVLink
          data={data}
          filename="data.csv"
          className="hidden"
          ref={csvLink}
          target="_blank" 
       />
      </div>
    </div>
  </div>
)

export default DataExport