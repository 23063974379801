import React, { Component } from 'react';
import RecurringTranscriptionsWidgetComponent from './RecurringTranscriptionsWidgetComponent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as RecurringTranscriptionsWidgetActions from './RecurringTranscriptionsWidgetActions'

class RecurringTranscriptionsWidget extends Component {

  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    this.props.actions.fetchRecurrences()
  }

  render() {
    return (
      <RecurringTranscriptionsWidgetComponent 
        fetchError={this.props.fetchError}
        recurringTranscriptions={this.props.recurringTranscriptions}
      />
    )
  }

}


function mapStateToProps(state) {
  return {
    fetchError: state.recurringTranscriptionsFetchError, 
    recurringTranscriptions: state.recurringTranscriptions
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, RecurringTranscriptionsWidgetActions), dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecurringTranscriptionsWidget)
