import {Fragment } from 'react';
import React from 'react';
import { Dialog, Transition, Listbox } from "@headlessui/react";
import { Link } from 'react-router-dom'

import Header from '../Header/header'
import Dropzone from '../containers/dropzone'

import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

const steps = [
  { id: '01', name: 'Upload Images', href: '#', status: 'current' },
  { id: '02', name: 'Review Paragraphs', href: '#', status: 'upcoming' },
  { id: '03', name: 'Review Text', href: '#', status: 'upcoming' },
  { id: '04', name: 'Export Text', href: '#', status: 'upcoming' },
]

const NotebookCreate = ({ 
  onTitleChange, 
  title, 
  onSubmitNotebookClick, 
  disabled, 
  uploadPercentage, 
  onParagraphDetectionToggle, 
  paragraphDetection, 
  currentProjectId,
  selectedProject, 
  projects, 
  setProjectSelected
}) => (
  <div className="bg-gray-100 min-h-screen">
    <Header/>
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <nav aria-label="Progress">
        <ol role="list" className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0 bg-white mt-8 ">
          {steps.map((step, stepIdx) => (
              <li key={step.name} className="relative md:flex-1 md:flex">
                {step.status === 'complete' ? (
                  <a href={step.href} className="group flex items-center w-full">
                    <span className="px-6 py-4 flex items-center text-sm font-medium">
                      <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                        <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
                      </span>
                      <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                    </span>
                  </a>
                ) : step.status === 'current' ? (
                  <a href={step.href} className="px-6 py-4 flex items-center text-sm font-medium" aria-current="step">
                    <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full">
                      <span className="text-indigo-600">{step.id}</span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-indigo-600">{step.name}</span>
                  </a>
                ) : (
                  <a href={step.href} className="group flex items-center">
                    <span className="px-6 py-4 flex items-center text-sm font-medium">
                      <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                        <span className="text-gray-500 group-hover:text-gray-900">{step.id}</span>
                      </span>
                      <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</span>
                    </span>
                  </a>
                )}

                {stepIdx !== steps.length - 1 ? (
                  <>
                    {/* Arrow separator for lg screens and up */}
                    <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                      <svg
                        className="h-full w-full text-gray-300"
                        viewBox="0 0 22 80"
                        fill="none"
                        preserveAspectRatio="none"
                      >
                        <path
                          d="M0 -2L20 40L0 82"
                          vectorEffect="non-scaling-stroke"
                          stroke="currentcolor"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </>
                ) : null}
              </li>
          ))}
        </ol>
      </nav>
    </div>
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:p-6">
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Notebook</h3>
            <p className="mt-1 text-sm text-gray-500">
              Let's get started by filling in the notebook name and selecting the folder containing the notebook pictures.
            </p>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form className="space-y-6" action="#" method="POST">
              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label for="notebook_name" className="block text-sm font-medium text-gray-700">Notebook name</label>
                  <input type="text" value={title} onChange={onTitleChange} name="notebook_name" id="notebook_name" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
                </div>
              </div>

              {/* <div className="grid grid-cols-3 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label for="notebook_name" className="block text-sm font-medium text-gray-700">Project</label>
                  <Listbox value={selectedProject} onChange={setProjectSelected}>
                    <div className="relative">
                      <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                        <span className="block truncate">{selectedProject.name}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <SelectorIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="z-40 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {projects.map((project, projectIdx) => (
                            <Listbox.Option
                              key={projectIdx}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                  active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-900'
                                }`
                              }
                              value={project}
                            >
                              {({ selectedProject }) => (
                                <>
                                  <span
                                    className={`block truncate ${
                                      selectedProject ? 'font-medium' : 'font-normal'
                                    }`}
                                  >
                                    {project.name}
                                  </span>
                                  {selectedProject ? (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>
              </div> */}

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Notebook pictures folder
                </label>
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                  <Dropzone/>
                </div>
              </div>

              <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="comments"
                    aria-describedby="comments-description"
                    name="comments"
                    type="checkbox"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    value={paragraphDetection}
                    onChange={onParagraphDetectionToggle}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="comments" className="font-medium text-gray-700">
                    Paragraph Detection
                  </label>
                  <p id="comments-description" className="text-gray-500">
                    Only needed if each image contains more than one paragraph.
                  </p>
                </div>
              </div>

              <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="comments"
                    aria-describedby="comments-description"
                    name="comments"
                    type="checkbox"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="comments" className="font-medium text-gray-700">
                    Use notebook as base
                  </label>
                  <p id="comments-description" className="text-gray-500">
                    If the notebook has the same characteristics. 
                  </p>
                </div>
              </div>

              {/* <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="comments"
                    aria-describedby="comments-description"
                    name="comments"
                    type="checkbox"
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    checked
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="comments" className="font-medium text-gray-700">
                    Line Detection
                  </label>
                  <p id="comments-description" className="text-gray-500">
                    Always needed unless you know what you are doing.
                  </p>
                </div>
              </div> */}

            </form>

            {disabled &&
              <div className="relative pt-8">
                <div className="flex mb-2 items-center justify-between">
                  <div>
                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-indigo-600 bg-indigo-200">
                      Uploading...
                    </span>
                  </div>
                  <div className="text-right">
                    <span className="text-xs font-semibold inline-block text-indigo-600">
                      {`${uploadPercentage}%`}
                    </span>
                  </div>
                </div>
                <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-indigo-200">
                  <div style={{ width: `${uploadPercentage}%` }} className="transition duration-300 ease-in-out shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-indigo-500"></div>
                </div>
                <p class="block text-sm font-medium text-gray-500 pointer-events-none">Don't leave this page until all images are processed!</p>
              </div>
            }

          </div>

        </div>
      </div>

      <div className="flex justify-end sm:p-6">
        <Link to={`/${currentProjectId}`}>
          <button type="button" className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Cancel
          </button>
        </Link>
        <button type="submit" disabled={disabled} onClick={onSubmitNotebookClick} className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          {disabled ? 'Uploading..' : 'Upload and Next'}
        </button>
      </div>
    </div>
  </div>
)

export default NotebookCreate